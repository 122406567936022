import React from 'react';
import { Card, Carousel, Row, Col, Typography } from 'antd';
import {
  SolutionOutlined,
  FileProtectOutlined,
  LineChartOutlined,
  BarChartOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';
import { useWindowDimensions } from '../utils/hooks';
import OtherStats from './Stats/OtherStats';
import { connect } from 'react-redux';
import { State, States } from 'sigt';
import { ChartCard } from 'ant-design-pro/lib/Charts';
import '../assets/css/components/Welcome.css';
import SedematStats from './Stats/SedematStats';
import EcodebaStats from './Stats/EcodebaStats';

const { Text, Title, Paragraph } = Typography;

const Welcome: React.FC<WelcomeProps> = ({ thm, auth, stats }) => {
  const { width } = useWindowDimensions();

  const otherStats = [1, 3, 5, 7, 9, 11, 13, 15, 20, 43, 17, 18, 19];
  const sedematStats = [24, 26, 27, 35, 37, 42, 55, 90];
  const seeingStats = [...otherStats, ...sedematStats];

  const isUsuario = auth.user?.tipoUsuario === 4;
  const canSeeStats = seeingStats.find((id) => auth.user?.institucion?.cargo?.id === id);
  const isSedemat = auth.user?.institucion?.id === 9;
  const isEcodeba = auth.user?.institucion?.id === 10;

  return (
    <Card
      // style={{ height: '100%' }}
      cover={
        auth.user?.tipoUsuario === 4 || !canSeeStats ? (
          <div id='cover' style={{ height: '55vh', padding: '1%' }}></div>
        ) : undefined
      }
      title={!isUsuario && auth.user && canSeeStats ? 'Estadísticas' : undefined}
      headStyle={
        !isUsuario ? { backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' } : undefined
      }
      bodyStyle={{
        height: 'calc(100% - 88px)',
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid #f0f0f0',
        position: 'relative',
      }}
    >
      {!isUsuario && auth.user && canSeeStats && !isSedemat && !isEcodeba && <OtherStats />}
      {!isUsuario && auth.user && canSeeStats && !isSedemat && isEcodeba && <EcodebaStats />}
      {!isUsuario && auth.user && canSeeStats && isSedemat && <SedematStats />}
      {(isUsuario || !auth.user || !canSeeStats) && (
        <Carousel dotPosition='top' autoplay autoplaySpeed={10000}>
          <div>
            <div className='content' style={{ marginTop: width < 600 ? '7%' : '4%' }}>
              <Row>
                <Col span={24}>
                  <Title style={{ textAlign: 'center' }} level={4}>
                    Solicitudes <FileProtectOutlined />
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph>
                    En la barra de navegación ubicada en la parte izquierda encontraras la sección{' '}
                    <Text strong>Solicitudes de trámites</Text>, en esta sección veras los trámites realizados previamente, además
                    podrás:
                    <ul>
                      <li>
                        Acceder a la Planilla de solicitud de cada una de las solicitudes realizadas haciendo clic el botón de
                        solicitud.
                      </li>
                      <li>Conocer el estado en el que se encuentra tu trámite.</li>
                      <li>Filtrar las Solicitudes por: N° solicitud, institución, tipo de trámite, fecha y estado.</li>
                    </ul>
                    <Text strong style={{ fontSize: 14 }}>
                      Nota: Al culminar la solicitud de algún trámite, será redireccionado automáticamente a esta sección.
                    </Text>
                  </Paragraph>
                </Col>
              </Row>
            </div>
          </div>
          <div>
            <div className='content' style={{ marginTop: width < 600 ? '7%' : '4%' }}>
              <Row>
                <Col span={24}>
                  <Title style={{ textAlign: 'center' }} level={4}>
                    Trámites <SolutionOutlined />
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Paragraph>
                    Si desea <Text strong>realizar un trámite</Text>, debe <Text strong>ubicar</Text> en la parte izquierda la{' '}
                    <Text strong>institución</Text> al cual pertenece, Seguidamente deberá seleccionar el trámite deseado y
                    completar una serie de datos correspondientes.
                  </Paragraph>
                </Col>
              </Row>
            </div>
          </div>
          <div>
            <div className='content' style={{ marginTop: width < 600 ? '7%' : '4%' }}>
              <Row>
                <Col span={24}>
                  <Title style={{ textAlign: 'center' }} level={4}>
                    Tus Estadísticas <LineChartOutlined />
                  </Title>
                </Col>
              </Row>
              <Row gutter={24} style={{ marginTop: 10 }}>
                <Col xl={8} xs={24}>
                  <ChartCard
                    title='Total de trámites realizados'
                    total={stats?.totalCount || 'N/A'}
                    avatar={width > 1360 ? <BarChartOutlined style={{ width: 30, height: 30 }} className='bigicon' /> : undefined}
                  />
                </Col>
                <Col xl={8} xs={24}>
                  <ChartCard
                    title='Total de trámites completados'
                    total={stats?.approvedCount || 'N/A'}
                    avatar={
                      width > 1360 ? (
                        <CheckCircleFilled style={{ width: 30, height: 30, color: '#92CD20' }} className='bigicon' />
                      ) : undefined
                    }
                  />
                </Col>
                <Col xl={8} xs={24}>
                  <ChartCard
                    title='Total de trámites rechazados'
                    total={stats?.rejectedCount || 'N/A'}
                    avatar={
                      width > 1360 ? (
                        <CloseCircleFilled style={{ width: 30, height: 30, color: '#E1422E' }} className='bigicon' />
                      ) : undefined
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Carousel>
      )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, stats: state.st.stats });

export default connect(mapStateToProps)(Welcome);

interface WelcomeProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  stats: States.Stats['stats'];
}
