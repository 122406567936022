import React, { useEffect, useState } from 'react';
import { TypeFee, createFee, getAllFee } from '../../../services/CleaningFee';
import { Descriptions, Col, Form, Row, Select, Checkbox, Button, message } from 'antd';
import { useForm } from 'antd/lib/form/util';
import { useDispatch, useSelector } from 'react-redux';
import { State, Taxpayer } from 'sigt';
import moment from 'moment';
import FormCleaningFee from './FormCleaningFee';
import { formatCurrency } from '../../../utils/formatNumber';
import { addRequestEcodeba } from '../../../redux/actions/requests';

const ResidentialCleaning: React.FC<ResidentialCleaningProps> = ({ taxpayer }) => {
  const [residencial, setResidencial] = useState<Residencial[] | null>(null);
  const [residencialSector, setResidencialSector] = useState<ResidencialSector[] | null>(null);
  const [dataSelected, setDataSelected] = useState<DataSelected>({ parroquia: null, residencial: null, residencialSector: null });
  const [loading, setLoading] = useState(false);
  const [checkboxSector, setCheckboxSector] = useState(false);
  const dispatch = useDispatch();

  const IVA = 0;
  const [form] = useForm();
  const parroquia = useSelector((state: State) => state.prsh.parish);
  const auth = useSelector((state: State) => state.auth);

  useEffect(() => {
    if (auth) {
      getAllFee(auth?.token ?? '').then((res) => {
        setResidencial(res.data.residencial);
        setResidencialSector(res.data.residencialSector);
      });
      setInitialValues();
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (taxpayer) setInitialValues();
    // eslint-disable-next-line
  }, [taxpayer]);

  const handleDataSelected = (field: string, value: string | number | null) => {
    setDataSelected((state: DataSelected) => ({ ...state, [field]: value }));
  };

  const handleSelectParent = (value) => {
    form.setFieldsValue({
      parroquia: value,
      comunidad: null,
    });
    handleDataSelected('comunidad', null);
  };

  const setInitialValues = () => {
    if (auth?.user?.tipoUsuario === 4) {
      form.setFieldsValue({
        direccion: auth?.user?.contribuyente?.direccion ?? '',
        documento: auth?.user?.contribuyente?.documento ?? '',
        tipoDocumento: auth?.user?.contribuyente?.tipoDocumento ?? '',
      });
    } else {
      form.setFieldsValue({
        direccion: taxpayer?.direccion ?? '',
        documento: taxpayer?.documento ?? '',
        tipoDocumento: taxpayer?.tipoDocumento ?? '',
      });
    }
  };

  const submit = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      const data = {
        idTarifa: checkboxSector ? values.sector : values.comunidad,
        documento: values.documento,
        tipoDocumento: values.tipoDocumento,
        rim: values.rim,
        fecha: {
          month: moment(values.periodo).locale('es').format('MMMM'),
          year: moment(values.periodo).locale('es').format('YYYY'),
        },
        direccion: values.direccion,
        iva: IVA,
      };

      const type: TypeFee = checkboxSector ? 'residencialSector' : 'residencial';

      const response = await createFee(type, data, auth?.token ?? '');
      if (response.status === 200) {
        message.success('Liquidacion procesada exitosamente.');
        form.resetFields();
        setInitialValues();
        setDataSelected({ parroquia: null, residencial: null, residencialSector: null });
        dispatch(addRequestEcodeba(response.data.solicitud));
      }
    } catch (e) {
      message.error(
        e.response?.data?.error || e.response?.data?.message || e.response?.message || 'Error al momento procesar la liquidacion'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={24}>
      <Form
        form={form}
        layout='vertical'
        scrollToFirstError
        onFinish={() => submit()}
        onValuesChange={(c, v) => {
          form.setFieldsValue(v);
        }}
      >
        <Col span={24}>
          <Checkbox checked={checkboxSector} onChange={() => setCheckboxSector(!checkboxSector)}>
            Liquidar Tarifa de aseo por Sector
          </Checkbox>
        </Col>

        <Col span={24}>
          {checkboxSector ? (
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item label='Sector' name='sector' rules={[{ required: true, message: 'Debe seleccionar un sector' }]}>
                  <Select onChange={(value: number) => handleDataSelected('residencialSector', value)}>
                    {residencialSector &&
                      residencialSector.map((p) => (
                        <Select.Option key={p.sector} value={p.id}>
                          {p.sector}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col span={10}>
                <Form.Item
                  label='Parroquia'
                  name='parroquia'
                  rules={[{ required: true, message: 'Debe seleccionar una parroquia' }]}
                >
                  <Select
                    onChange={(value: number) => {
                      handleSelectParent(value);
                      handleDataSelected('parroquia', value);
                    }}
                  >
                    {parroquia &&
                      parroquia.map((p) => (
                        <Select.Option key={p.nombre} value={p.id}>
                          {p.nombre}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label='Comunidad'
                  name='comunidad'
                  rules={[{ required: true, message: 'Debe seleccionar una comunidad' }]}
                >
                  <Select onChange={(value: number) => handleDataSelected('residencial', value)}>
                    {residencial &&
                      residencial
                        .filter((p) => p.idParroquia === dataSelected.parroquia)
                        .map((p) => (
                          <Select.Option key={p.comunidad} value={p.id}>
                            {p.comunidad}
                          </Select.Option>
                        ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <FormCleaningFee />

          <Descriptions>
            {checkboxSector ? (
              <>
                <Descriptions.Item label='Tarifa'>
                  {residencialSector &&
                    `${formatCurrency(residencialSector.find((p) => p.id === dataSelected.residencialSector)?.tarifa ?? 0)} MMV`}
                </Descriptions.Item>
                <Descriptions.Item label='IVA'>
                  {residencialSector &&
                    formatCurrency((residencialSector.find((p) => p.id === dataSelected.residencialSector)?.tarifa ?? 0) * IVA)}
                </Descriptions.Item>
                <Descriptions.Item label='Monto Total'>
                  {residencialSector &&
                    formatCurrency(
                      +(residencialSector.find((p) => p.id === dataSelected.residencialSector)?.tarifa ?? 0) +
                        +(residencialSector.find((p) => p.id === dataSelected.residencialSector)?.tarifa ?? 0) * IVA
                    )}
                </Descriptions.Item>
              </>
            ) : (
              <>
                <Descriptions.Item label='Tarifa'>
                  {residencial &&
                    `${formatCurrency(residencial.find((p) => p.id === dataSelected.residencial)?.tarifa ?? 0)} MMV`}
                </Descriptions.Item>
                <Descriptions.Item label='IVA'>
                  {residencial &&
                    formatCurrency(+(residencial?.find((p) => p.id === dataSelected.residencial)?.tarifa ?? 0) * IVA)}
                </Descriptions.Item>
                <Descriptions.Item label='Monto Total'>
                  {residencial &&
                    formatCurrency(
                      +(residencial?.find((p) => p.id === dataSelected.residencial)?.tarifa ?? 0) +
                        +(residencial?.find((p) => p.id === dataSelected.residencial)?.tarifa ?? 0) * IVA
                    )}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </Col>
        <Col span={24}>
          <Button type='primary' loading={loading} onClick={form.submit}>
            Generar
          </Button>
        </Col>
      </Form>
    </Row>
  );
};

export default ResidentialCleaning;

interface ResidentialCleaningProps {
  taxpayer: Taxpayer | null | undefined;
}
interface DataSelected {
  parroquia: number | null;
  residencial: number | null;
  residencialSector: number | null;
}

interface Residencial {
  id: number;
  idParroquia: number;
  tarifa: number;
  comunidad: string;
}

interface ResidencialSector {
  id: number;
  tarifa: number;
  sector: string;
}
