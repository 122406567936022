import React, { useEffect, useState } from 'react';
import { Row, Col, Typography,  message, Divider, Card } from 'antd';
import {  MiniProgress } from 'ant-design-pro/lib/Charts';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import 'moment/locale/es';
import '../../assets/css/components/Statistics.css';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import Axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

const EcodebaStats: React.FC<StatisticsProps> = ({ stats, auth }) => {
  const [ecodebaStats, setEcodebaStats] = useState<any>(null);

  useEffect(() => {
    getStats();
    // eslint-disable-next-line
  }, []);

  const getStats = async () => {
    try {
      const response = await Axios.get(`${server}/stats/ecodeba`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setEcodebaStats(response.data.stats);
      console.log(response);
    } catch (error) {
      message.error(error?.response?.data?.message ?? error?.message ?? 'Error en servidor');
    }
  };

  const DEFAULT = {
    totalCount: 'Total de liquidaciones',
    monthCount: 'Liquidaciones recibidos este mes',
    gainsCount: 'Liquidaciones recibidos en el mes',
    lineCount: 'Total de Liquidaciones por atender',
    lineGraph: 'Liquidaciones recibidos por día',
    progressCount: 'Total de Liquidaciones completados',
    progress: 'Porcentaje de Liquidaciones completados',
    progressGains: 'Liquidaciones completados en el mes',
    pieTitle: 'Liquidaciones según su estado',
    insidePie: 'Liquidaciones',
    // pieFormat: () => ecodebaStats?.totalGraph?.count,
    legendFormat: (value) => <span>{value}</span>,
    barTitle: 'Liquidaciones por tiempo',
    totalCollected: 'Total Recaudado en el mes',
  };

  const getTitle = (prop: string) => {
    return DEFAULT[prop];
  };

  return (
    ecodebaStats &&
    ecodebaStats.map((e) => (
      <Row key={e.idSubRamo} gutter={[12, 12]} style={{ width: '100%' }}>
        <Divider orientation='left'>
          <Typography.Title level={4}>{`Liquidaciones ${e.descripcion}`}</Typography.Title>
        </Divider>
        <Col span={24}>
          <Card>
            <NumberInfo
              subTitle={<span>{getTitle('totalCollected')}</span>}
              total={e.stats?.totalCollectedGraph?.count || 'N/A'}
              style={{ marginBottom: 10, width: '100%' }}
            />
          </Card>
        </Col>
        <Col span={12} style={{ marginTop: 0 }}>
          <Card>
            <NumberInfo
              subTitle={<span>{getTitle('totalCount')}</span>}
              total={e?.stats?.totalGraph?.count || 'N/A'}
              style={{ marginBottom: 10, width: '100%' }}
            />

            <NumberInfo
              subTitle={<span>{getTitle('monthCount')}</span>}
              total={e?.stats?.totalGraph?.monthCount || 'N/A'}
              status='up'
              style={{ marginBottom: 10, width: '100%' }}
            />

            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {getTitle('gainsCount')}: &nbsp;
              <span style={{ fontWeight: 'bolder' }}>
                {isNaN(e?.stats?.totalGraph?.gains as unknown as number) ? '0' : e?.stats?.totalGraph?.gains}%
              </span>
              &nbsp;
              {e?.stats?.totalGraph?.gains && e?.stats?.totalGraph?.gains > 0 ? (
                <ArrowUpOutlined style={{ color: 'green' }} />
              ) : (
                <ArrowDownOutlined style={{ color: 'red' }} />
              )}
            </span>
          </Card>
        </Col>
        <Col span={12} style={{ marginTop: 0 }}>
          <Card>
            <NumberInfo
              subTitle={<span>{getTitle('progressCount')}</span>}
              total={e.stats?.progressChart?.count || 'N/A'}
              style={{ marginBottom: 10, width: '100%' }}
            />

            <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
              {getTitle('progressGains')}:&nbsp;
              <span style={{ fontWeight: 'bolder' }}>
                {isNaN(e.stats?.progressChart?.percentage as unknown as number) ? '0' : e.stats?.progressChart?.percentage}%
              </span>
            </span>

            <MiniProgress percent={e.stats?.progressChart?.percentage || 0} strokeWidth={20} target={90} targetLabel='Objetivo' />
            <Divider style={{ marginBlock: 4 }} />
            <div style={{ height: 30 }}>
              <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
                {getTitle('progress')}: &nbsp;
                <span style={{ fontWeight: 'bolder' }}>
                  {isNaN(e.stats?.progressChart?.gains as unknown as number) ? '0' : e.stats?.progressChart?.gains}%
                </span>
                &nbsp;
                {e.stats?.progressChart?.gains && e.stats.progressChart.gains > 0 ? (
                  <ArrowUpOutlined style={{ color: 'green' }} />
                ) : (
                  <ArrowDownOutlined style={{ color: 'red' }} />
                )}
              </span>
            </div>
          </Card>
        </Col>
      </Row>
    ))
  );
};

const mapStateToProps = (state: State) => ({ stats: state.st.stats, auth: state.auth });

export default connect(mapStateToProps)(EcodebaStats);

interface StatisticsProps {
  stats: States.Stats['stats'];
  auth: States.Auth;
}

interface defaultStats {
  totalCount?: number;
  approvedCount?: number;
  rejectedCount?: number;
  totalGraph?: {
    count: number;
    monthCount: number;
    gains: number;
  };
  lineChart?: {
    count: number;
    graph: { x: string; y: number }[];
  };
  progressChart?: {
    count: number;
    percentage: number;
    gains: number;
  };
  pieChart?: {
    graph: { x: string; y: number }[];
  };
  barChart?: {
    daily: { x: string; y: number }[];
    monthly: { x: string; y: number }[];
    yearly: { x: string; y: number }[];
  };
}

