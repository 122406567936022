import { Button, Card, Input, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { SendOutlined } from '@ant-design/icons';
import styles from './virtualAssistant.module.css';
import { getThreadId, sendMessage } from './services';
import Message from './Message';
import { getFileFromS3 } from '../../utils/aws';

export const VirtualAssistant: React.FC = () => {
  const thm = useSelector((state: State) => state.thm);
  const { width } = useWindowDimensions();
  const [messageList, setMessageList] = useState<IMessage[]>([]);
  const [thread, setThread] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    getThreadId()
      .then((res) => {
        setThread(res.thread_id);
      })
      .catch((err) => {
        message.error('Ha ocurrido un error, intenta de nuevo');
        console.log('Error al obtener el thread_id', err);
      });

    return () => {
      setThread(null);
      setMessageList([]);
      setInputValue('');
    };
  }, []);

  const chatContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messageList]);

  const handleMessage = async () => {
    const errorMessage = 'No podemos procesar tu mensaje, intenta de nuevo';

    try {
      setLoading(true);

      if (!inputValue && inputValue?.length === 0 && inputValue === '')
        return message.error('Mensaje invalido, debe ingresar un prompt');
      if (!thread) throw Error('Vuelva a carga el asistente virtual');

      setMessageList((state) => [
        ...state,
        { message: inputValue, role: 'USER', fileInfo: null, fileToRender: null, prompt: inputValue },
      ]);

      const data = {
        thread_id: thread,
        prompt: inputValue,
      };

      let url: string | null = null;
      const response = await sendMessage(data);

      if (response.status !== 200) throw Error(errorMessage);

      if (response?.message && ['internal server error'].includes(response?.message?.toLocaleLowerCase())) {
        throw Error(errorMessage);
      }

      if (response?.file) {
        const fileType = response?.file?.type;
        const image = await getFileFromS3(response?.file?.bucket_key);
        const buffer = await new Response(image as any).arrayBuffer();
        const blob = new Blob([buffer], { type: fileType === 'image' ? 'image/png' : 'text/csv' });
        url = URL.createObjectURL(blob);
      }

      setInputValue('');
      setMessageList((state) => [
        ...state,
        {
          message: response.message,
          role: 'VIRTUAL_ASSISTANT',
          fileInfo: response?.file,
          fileToRender: url,
          prompt: inputValue,
        },
      ]);
    } catch (error) {
      await getThreadId();
      //@ts-ignore
      message.error(error?.message ?? errorMessage);
      console.log('handleMessage', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Asistente Virtual'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <section ref={chatContainerRef} className={styles.chat}>
        {messageList.length > 0 && messageList.map((m, index) => <Message key={index} {...m} />)}
      </section>
      <section className={styles.footer}>
        <Input
          style={{ width: '95%', border: 'none', height: '3rem', backgroundColor: '#f3f3f3' }}
          placeholder='Escribe tu mensaje'
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          disabled={loading}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleMessage();
            }
          }}
        />
        <Button
          onClick={handleMessage}
          type='primary'
          shape='circle'
          size='large'
          icon={<SendOutlined color={thm.primaryColor} />}
          loading={loading}
        />
      </section>
    </Card>
  );
};

export interface IMessage {
  message: string;
  role: 'VIRTUAL_ASSISTANT' | 'USER';
  fileInfo?: IFile | null;
  fileToRender?: string | null;
  prompt: string;
}

interface IFile {
  bucket_key: string;
  file_name: string;
  type: string;
}
