import axios from 'axios';
const server = 'https://l1ptmeprm7.execute-api.us-east-1.amazonaws.com';
export const getThreadId = async () => {
  try {
    const response = await axios.post(`${server}/prod/thread`, null, {
      headers: {
        'x-api-key': `${process.env.REACT_APP_VIRTUAL_ASSISTANT_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMessage = async (data: sendMessageDto) => {
  try {
    const resJson = await fetch(`https://mfzz7ju67epd7brtu5czqobrmm0bnftd.lambda-url.us-east-1.on.aws`, {
      method: 'POST',
      body: JSON.stringify(data),
    });

    const response = await resJson.json();

    return { ...response, status: resJson.status };
  } catch (error) {
    throw error;
  }
};

export const messageRating = async (data: messageRatingDto) => {
  try {
    const resJson = await fetch(`${server}/prod/barinas/ai-rating`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'x-api-key': `${process.env.REACT_APP_VIRTUAL_ASSISTANT_TOKEN}`,
      },
    });

    const response = await resJson.json();

    return { ...response, status: resJson.status };
  } catch (error) {
    throw error;
  }
};

interface sendMessageDto {
  prompt: string;
  thread_id: string;
}

interface messageRatingDto {
  prompt: string;
  ai_answer: string;
  is_good: 'True' | 'False';
}
