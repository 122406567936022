import React, { useState } from 'react';
import { Table, Form, Row, Col, Select, Input, DatePicker, Divider, Button, Typography, InputNumber } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { States, State, Institucion } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { Utils } from '../../../utils/validators';
import VoucherUpload from './VoucherUpload';

const MultipleWireTransfers: React.FC<MultiplePaymentsProps> = ({
  banks,
  selectedInstitution,
  addVoucher,
  removeVoucher,
  form,
}) => {
  // BOD, BANESCO Y BNC
  const sedematBanks = [11, 65, 39, 38, 43, 67, 74, 58, 63, 68, 2];
  const ecodebaBanks = [2, 11];
  const destinationBank = selectedInstitution?.id === 10 ? ecodebaBanks : sedematBanks;

  const bankColumns: ColumnsType<any> = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left',
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta',
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    },
  ];

  const handleBankChange = (banco) => {
    form.setFieldsValue({
      banco,
    });
  };

  const firstListItem = (fields, add) => {
    if (fields.length === 0) {
      setTimeout(() => add(), 10);
    }
  };

  return (
    <React.Fragment>
      <Table
        bordered
        pagination={false}
        style={{ marginBottom: 15 }}
        columns={bankColumns}
        dataSource={selectedInstitution?.cuentasBancarias?.map((c) => ({
          ...c,
          documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
          banco: banks?.find((b) => b.id === c.banco)?.nombre,
          key: c.id,
        }))}
      />
      <Form.List name='pagos'>
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add)}
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Transferencia {index + 1}
                  </Typography.Text>
                </Divider>
                <Form.Item required={true} key={field.key} style={{ width: '100%', margin: 0 }}>
                  <Row gutter={24} style={{ padding: '0 20px' }}>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Banco'
                        name={[field.name, 'banco']}
                        fieldKey={field.fieldKey}
                        rules={[{ required: true, message: 'Debe indicar el banco' }]}
                      >
                        {banks ? (
                          <Select onChange={handleBankChange} placeholder='Banco'>
                            {banks
                              .filter((b) => b.id !== 75)
                              .map((b, i) => (
                                <Select.Option key={`b-${b.id}`} value={b.id}>
                                  {b.nombre}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <div></div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label='Banco de Destino'
                        name={[field.name, 'destino']}
                        fieldKey={field.fieldKey}
                        rules={[{ required: true, message: 'Debe indicar el banco' }]}
                      >
                        {banks ? (
                          <Select onChange={handleBankChange} placeholder='Banco de Destino'>
                            {banks
                              ?.filter((b) => selectedInstitution?.cuentasBancarias?.map((c) => c.banco).includes(b.id))
                              .map((b, i) => (
                                <Select.Option key={`b-${b.id}`} value={b.id}>
                                  {b.nombre}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : (
                          <div></div>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Numero de Referencia'
                        name={[field.name, 'referencia']}
                        fieldKey={field.fieldKey}
                        rules={[
                          { required: true, message: 'Por favor ingrese el numero de referencia.' },
                          { validator: Utils.validators.alphaNumeric },
                        ]}
                      >
                        <Input placeholder='Referencia' />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Fecha de Pago'
                        name={[field.name, 'fecha']}
                        fieldKey={field.fieldKey}
                        rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'DD-MM-YYYY'}
                          disabledDate={(current) => current > moment().endOf('day')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={6}>
                      <Form.Item
                        label='Monto'
                        name={[field.name, 'costo']}
                        fieldKey={field.fieldKey}
                        rules={[{ required: true, message: 'Por favor ingrese el monto del pago.' }]}
                      >
                        <InputNumber
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                          min={0}
                          step={1}
                          style={{ width: '100%' }}
                          placeholder='Monto'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col style={{ marginLeft: 20, marginTop: 20 }}>
                    <VoucherUpload
                      showVoucher={true}
                      onUpload={(e) => {
                        addVoucher(e);
                      }}
                      onRemove={(e) => {
                        removeVoucher(e);
                      }}
                    />
                  </Col>
                </Form.Item>
              </React.Fragment>
            ))}
            <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {fields.length > 1 && (
                <Col>
                  <Button type='danger' onClick={() => remove(fields.length - 1)}>
                    Eliminar Transferencia
                  </Button>
                </Col>
              )}
              <Col>
                <Button type='dashed' onClick={() => add()}>
                  Agregar Transferencia
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Form.List>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ banks: state.bk.banks });

export default connect(mapStateToProps)(MultipleWireTransfers);

interface MultiplePaymentsProps {
  banks: States.Banks['banks'];
  selectedInstitution?: Institucion;
  form: FormInstance;
  addVoucher?: any;
  removeVoucher?: any;
}
