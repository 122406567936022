import { Card, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../utils/hooks';
import { useSelector } from 'react-redux';
import { State, Taxpayer } from 'sigt';
import ResidentialCleaning from './ResidentialCleaning';
import CommercialCleaning from './CommercialCleaning';
import { useHistory, useParams } from 'react-router';
import GetTaxpayerInfo from '../../Taxpayer/components/GetTaxpayerInfo';

const CleaningFee: React.FC = () => {
  const [tab, setTab] = useState('1');
  const { width } = useWindowDimensions();
  const themeColor = useSelector((state: State) => state.thm);
  const auth = useSelector((state: State) => state.auth);
  const [taxpayer, setTaxpayer] = useState<Taxpayer | null>(null);
  const { id } = useParams<{ id: any }>();
  const history = useHistory();

  useEffect(() => {
    setTaxpayer((history.location.state as any)?.contribuyente);
  }, [history.location]);

  return (
    <Card
      style={{ height: '100%' }}
      title='Tarifa de aseo'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{
        height: 64,
        backgroundColor: themeColor.primaryColor,
        padding: width < 992 ? '0 10px' : '0 20px',
        color: 'white',
      }}
    >
      {!id && auth.user?.tipoUsuario !== 4 && <GetTaxpayerInfo />}
      {(id || auth.user?.tipoUsuario === 4) && (
        <Tabs activeKey={tab} onChange={(key) => setTab(key)}>
          <Tabs.TabPane tab='Aseo Residencial' key='1'>
            <ResidentialCleaning taxpayer={taxpayer} />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Aseo Comercial' key='2'>
            <CommercialCleaning taxpayer={taxpayer} />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Card>
  );
};

export default CleaningFee;
