import React, { useState, useEffect } from 'react';
import { SearchOutlined, CalendarOutlined, FileProtectOutlined, FileDoneOutlined, FolderOutlined } from '@ant-design/icons';
import { Table, Tooltip, Button, Row, DatePicker, Col, Popover, Tag } from 'antd';
import { connect, useSelector } from 'react-redux';
import { State, States, Liquidacion } from 'sigt';
import { useWindowDimensions } from '../../utils/hooks';
import { ColumnsType } from 'antd/lib/table';
import _ from 'lodash';
import handlingMessage from '../../utils/handlingMessage';
import { createFile } from '../../redux/actions/taxes';
import moment, { Moment } from 'moment';
import Petro from '../Icons/Petro';

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const TableTaxes: React.FC<PropsTableTaxes> = ({ thm, tax, auth, createFile, preloadedData }) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
  const [keys, setKeys] = useState<Moment[]>([]);
  const petro = useSelector((state: State) => state.coin.petro);

  enum UserType {
    SuperUser = 1,
    OfficialAdmin,
    Official,
    External,
    OfficialChief,
    DepartmentChief,
  }

  const getState = (estado) => {
    switch (estado) {
      case 'ingresardatos':
        return { name: 'En espera de pago', color: 'cyan' };
      case 'validando':
        return { name: 'Validando pago', color: 'warning' };
      case 'iniciado':
        return { name: 'Iniciado', color: 'geekblue' };
      default:
        return { name: 'Solvente', color: 'green' };
    }
  };
  const getTypeName = (ramo, nombreCorto) => {
    switch (nombreCorto) {
      case 'AE':
        return 'ACTIVIDADES ECONOMICAS';
      default:
        return ramo;
    }
  };
  const data: any[] = [];
  const filterTypes: any[] = [];
  const filterStatus: any[] = [];
  const tx = preloadedData || tax.taxes;
  tx.forEach((ele) => {
    const state = getState(ele.estado);
    data.push({ ...ele, state });
    if (typeof _.find(filterTypes, (e) => e.value === ele.ramo) !== 'object')
      filterTypes.push({ value: ele.ramo, text: getTypeName(ele.ramo, ele.nombreCorto) });
    if (typeof _.find(filterStatus, (e) => e.value === state.name) !== 'object')
      filterStatus.push({ value: state.name, text: state.name });
  });

  useEffect(() => {
    // setLoading(data.length > 0 ? false : true)
    setLoading(false);
  }, [data]);

  const generateFile = (record: Liquidacion, tipoCertificado: string) => {
    handlingMessage({
      action: () => createFile(record.id, tipoCertificado, auth.token || ''),
      key: 'file',
      loadingMessage: 'Realizando operación...',
      cb: (data) => {
        if (data.status === 200) {
          const win = window.open(data.media, '_blank');
          win?.focus();
        }
        setDisabled(false);
      },
    });
  };

  const getFile = (record: Liquidacion, tipoCertificado: string) => {
    setDisabled(true);
    switch (tipoCertificado) {
      case 'solvencia':
        if (record.certificado) {
          const win = window.open(record.certificado, '_blank');
          win?.focus();
          setDisabled(false);
        } else {
          generateFile(record, tipoCertificado);
        }
        break;
      case 'certificado':
        if (record.certificado) {
          const win = window.open(record.certificado, '_blank');
          win?.focus();
          setDisabled(false);
        } else {
          generateFile(record, tipoCertificado);
        }
        break;
      default:
        if (record.recibo) {
          const win = window.open(record.recibo, '_blank');
          win?.focus();
          setDisabled(false);
        } else {
          generateFile(record, tipoCertificado);
        }
        break;
    }
  };
  const aux = (record, type) => (
    <div>
      <Row gutter={[8, 8]}>
        <Tooltip title='Recibo' visible={visible}>
          <Col span={24}>
            <Button
              style={{ width: '100%' }}
              onClick={() => {
                getFile(record, 'recibo');
              }}
              shape='round'
              type='primary'
              icon={<FileDoneOutlined />}
              onMouseOver={() => (width > 440 ? setVisible(false) : setVisible(true))}
              onMouseLeave={() => setVisible(false)}
              disabled={disabled}
              size='small'
            >
              {width > 440 ? 'Recibo' : null}
            </Button>
          </Col>
        </Tooltip>
      </Row>
      {auth.user?.tipoUsuario === UserType.External && (
        <Row gutter={8}>
          <Tooltip title='Solvencia' visible={visible}>
            <Col span={24}>
              <Button
                style={{ width: '100%' }}
                disabled={disabled}
                onClick={() => {
                  getFile(record, type);
                }}
                shape='round'
                type='primary'
                icon={<FileProtectOutlined />}
                size='small'
              >
                {width > 440 ? 'Solvencia' : null}
              </Button>
            </Col>
          </Tooltip>
        </Row>
      )}
    </div>
  );
  const renderAction = (record: Liquidacion) => {
    switch (record.nombreCorto) {
      case 'AE':
        return (
          <Popover content={aux(record, 'solvencia')} placement='bottom' trigger='click'>
            <Tooltip title='Documentos' visible={visible}>
              <Button
                shape='round'
                target='_blank'
                href={record.certificado}
                type='primary'
                icon={<FolderOutlined />}
                onMouseOver={() => (width > 440 ? setVisible(false) : setVisible(true))}
                onMouseLeave={() => setVisible(false)}
                size='small'
              >
                {width > 440 ? 'Documentos' : null}
              </Button>
            </Tooltip>
          </Popover>
        );
      case 'IU':
        return (
          <Popover content={aux(record, 'certificado')} placement='bottom' trigger='click'>
            <Tooltip title='Documentos' visible={visible}>
              <Button
                shape='round'
                target='_blank'
                href={record.certificado}
                type='primary'
                icon={<FolderOutlined />}
                onMouseOver={() => (width > 440 ? setVisible(false) : setVisible(true))}
                onMouseLeave={() => setVisible(false)}
                size='small'
              >
                {width > 440 ? 'Documentos' : null}
              </Button>
            </Tooltip>
          </Popover>
        );
      default:
        return (
          <Tooltip title='Recibo' visible={visible}>
            <Button
              shape='round'
              onClick={() => {
                getFile(record, record.nombreCorto === 'MUL' ? 'multa' : 'recibo');
              }}
              type='primary'
              icon={<FileDoneOutlined />}
              onMouseOver={() => (width > 440 ? setVisible(false) : setVisible(true))}
              onMouseLeave={() => setVisible(false)}
              disabled={disabled}
              size='small'
            >
              {width > 440 ? 'Recibo' : null}
            </Button>
          </Tooltip>
        );
    }
  };
  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              picker='month'
              style={{ width: '100%' }}
              format='MMMM, YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(`${record[dataIndex].month.toLowerCase()}, ${record[dataIndex].year}`, 'MMMM, YYYY').utc().format('MMMM, YYYY') ===
          keys[0]?.utc().format('MMMM, YYYY') ||
        moment(`${record[dataIndex].month.toLowerCase()}, ${record[dataIndex].year}`, 'MMMM, YYYY')
          .utc()
          .isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo',
      filters: filterTypes,
      ellipsis: true,
      width: '30%',
      onFilter: (value, record) => {
        return record.ramo.indexOf(value as string) === 0;
      },
      dataIndex: 'ramo',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      align: 'center',
      ...getColumnFilterDate('fecha'),
      render: (text) => (text?.month ? `${firstLetterMayus(text.month)} ${text.year}` : undefined),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      align: 'center',
      render: (value, record) => (
        <Row justify='center' align='middle' gutter={4}>
          <Col>
            <Petro style={{ marginBottom: '2px' }} />
          </Col>
          <Col>{Number(+(+record?.montoPetro || 0)?.toFixed(8))}</Col>
        </Row>
      ),
    },
    {
      title: 'Monto (Bs.)',
      dataIndex: 'monto',
      align: 'center',
      render: (monto, record) =>
        monto ? `Bs. ${formatCurrency(+monto)}` : `Bs. ${formatCurrency(Math.round(+(+record.montoPetro * petro).toFixed(2)))}`,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      align: 'center',
      filters: filterStatus,
      onFilter: (value, record) => record.state.name.toLowerCase().indexOf((value as string).toLowerCase()) === 0,
      render: (value, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (text, record) =>
        record.estado === 'finalizado' && ['SM', 'PP', 'IU', 'MUL', 'AE', 'TA/ECODEBA'].includes(record.nombreCorto)
          ? renderAction(record)
          : null,
    },
  ];

  return (
    <Table
      style={{ height: '100%' }}
      size='middle'
      pagination={{ pageSize: 6 }}
      loading={loading}
      columns={columns}
      dataSource={data}
      rowKey={(e) => e.id}
      bordered={!!preloadedData}
    />
  );
};
const mapStateToProps = ({ tax, thm, auth }: State) => ({ tax, thm, auth });

export default connect(mapStateToProps, { createFile })(TableTaxes);

interface PropsTableTaxes {
  tax: States.Taxes;
  thm: States.ThemeColors;
  auth: States.Auth;
  createFile: (id: number, tipoCertificado: string, token: string) => Promise<void>;
  preloadedData?: any;
}

interface TypeTaxes {
  [status: string]: string;
}
