import Axios from 'axios';
const server = process.env.REACT_APP_SERVER_URL;

export const getAllFee = async (token: string) => {
  try {
    const response = await Axios.get(`${server}/CleaningFee/AllFee`, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export type TypeFee = 'residencial' | 'residencialSector' | 'comercialActivity';

export const createFee = async (type: TypeFee, data: any, token: string) => {
  try {
    const response = await Axios.post(
      `${server}/CleaningFee/init/${type}`,
      { process: data },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
