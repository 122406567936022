export const formatNumber = (number) => {
  const regex = /^\d{1,3}(\.\d{3})*(,\d{2})?$/g;
  number += '';
  const test = regex.test(number);
  if (!test) return NaN;
  const newNumber = parseFloat(number.replaceAll('.', '').replace(',', '.'));
  return newNumber;
};

export const formatCurrency = (number: number) =>
  new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);
