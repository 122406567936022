import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Divider,
  Typography,
  Col,
  Descriptions,
  Form,
  Button,
  message,
  Alert,
  InputNumber,
  Collapse,
  Input,
} from 'antd';
import { States, State, Liquidacion, Request } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../utils/hooks';
import { useParams, useHistory } from 'react-router';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CloudDownloadOutlined, CreditCardFilled, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { changeRequestStatus, rebateRequest, changeRequestStatusEcodeba } from '../../redux/actions/requests';
import { cloneDeep, round } from 'lodash';
import { changeTaxData } from '../../redux/actions/taxes';
import MultipleTransactions from '../Forms/components/MultipleTransactions';
import MultipleWireTransfers from '../Forms/components/MultipleWireTransfers';
import Petro from '../Icons/Petro';
import { CargoEcodeba, CargoSedemat } from '../../routes';
const server = process.env.REACT_APP_SERVER_URL;

const PaySpecificTax: React.FC<PaySpecificTaxProps> = ({
  thm,
  requests,
  requestsEcodeba,
  banks,
  institutions,
  auth,
  ae,
  rebateRequest,
  changeRequestStatus,
  changeRequestStatusEcodeba,
  changeTaxData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [discount, setDiscount] = useState<boolean>(false);
  const [latePayFine, setLatePayFine] = useState<number>(0);

  // const [days, setDays] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [comprobantes, setComprobantes] = useState<any>([]);

  const petro = useSelector((state: State) => state.coin.petro) || 18000000;

  const { width } = useWindowDimensions();
  const { id } = useParams<{ id: any }>();
  const history = useHistory();
  const [form] = Form.useForm();

  const isECODEBA =
    history.location.pathname.includes('ecodeba') || (auth.user?.tipoUsuario !== 4 && auth.user?.institucion.id === 10);

  const canPay: number[] = [22, 25, 40, 41, 42, 53, CargoEcodeba.Cajero];
  const canDiscount: number[] = [90, 35, CargoSedemat.GerenteRecaudacion];
  const sedematBanks = [11, 65, 39, 38, 43, 67, 74, 58, 63, 68, 2];
  // const canReduce: number[] = [22, 25, 40, 41];
  const selectedReq = isECODEBA
    ? requestsEcodeba.find((r) => r.id === parseInt(id || '0'))
    : requests.find((r) => r.id === parseInt(id || '0'));
  const selectedInstitution = institutions?.find((i) => (isECODEBA ? i.id === 10 : i.id === 9));

  const aforos = useSelector((state: State) => state.ae.activities);

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);
  const firstLetterMayus = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  useEffect(() => {
    // if (!selectedReq) history.push('/dashboard');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedReq?.pagado) {
      const now = moment().utcOffset(-4).locale('ES');
      const currentMonth = now.clone();
      let declaredDate = new Date('2022-03-30T00:00:00.000Z');
      let getm = declaredDate.getMonth();
      let gety = declaredDate.getFullYear();
      const comparedMonth = moment().utcOffset(-4).locale('ES').month(getm).year(gety);
      const diff = currentMonth.startOf('month').diff(comparedMonth.startOf('month'), 'M');
      let percentage = 0;
      // if (diff >= 3) {
      //   if (diff < 6) {
      //     percentage = 0.50;
      //   }
      //   else if (diff < 12) {
      //     percentage = 0.60;
      //   }
      //   else if (diff < 36) {
      //     percentage = 1.00;
      //   }
      //   else {
      //     percentage = 1.50;
      //   }
      // }
      let monto = selectedReq?.monto ? Number(selectedReq?.monto) : (Number(selectedReq?.montoPetro) || 0) * petro;
      setLatePayFine(monto * percentage);
    }
  }, [selectedReq]);

  const pay = async () => {
    const values = await form.validateFields();
    Object.keys(values).forEach((k) => {
      if (moment.isMoment(values[k])) {
        values[k] = values[k].toISOString();
      }
    });
    const pagos = Array.isArray(form.getFieldValue('pagos')) ? cloneDeep(form.getFieldValue('pagos')) || [] : [];
    if (pagos.filter((p) => p).find((p) => p.metodoPago === 'TRANSFERENCIA' && !sedematBanks.includes(p.destino))) {
      return message.error('Los métodos de pago de transferencia y cheque deben ser realizados solo a los bancos de SAMAT');
    }
    pagos
      .filter((p) => p)
      .forEach((p) => {
        Object.keys(p).forEach((k) => {
          if (moment.isMoment(p[k])) {
            p[k] = p[k].toISOString();
          }
        });
      });
    const montoAPagar = round(
      +parseFloat(
        selectedReq?.monto
          ? selectedReq?.monto + latePayFine
          : ((+(Number(selectedReq?.montoPetro) || 0) * petro + latePayFine) as unknown as string) || '0'
      ).toFixed(2),
      2
    );
    if (pagos.map((p) => p.costo).reduce((c, v) => c + v, 0) < montoAPagar)
      return message.error('La suma de los montos de las transacciones debe ser igual o mayor al total de la liquidación');
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const procedimiento = {
        pagos,
        interesMoratorio: selectedReq?.interesMoratorio,
      };
      const response = await axios.put(
        `${server}/settlements/${id}/payment`,
        { procedimiento },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );

      if (response.status === 200) {
        const fd = new FormData();
        if (comprobantes) {
          comprobantes.forEach((e) => fd.append('comprobantes', e.file));

          const responseFile = await axios.post(`${server}/uploads/comprobantes/${id}`, fd, {
            headers: { Authorization: `Bearer ${auth.token}` },
          });
          if (responseFile.status !== 200) message.error('Error al subir los comprobantes');
        }

        if (isECODEBA) {
          changeRequestStatusEcodeba(response.data.solicitud.estado, parseInt(id || '0'));
        } else {
          changeRequestStatus(response.data.solicitud.estado, parseInt(id || '0'));
        }
        message.success('Pago registrado de manera exitosa');
        auth.user?.tipoUsuario === 4
          ? history.push(`/dashboard/${isECODEBA ? 'ecodeba/impuestos' : 'impuestos'}/pagar`)
          : history.push(`/dashboard/${isECODEBA ? 'ecodeba/impuestos' : 'impuestos'}/pendientes`);
        response.data.solicitud.liquidaciones.forEach((item) => {
          const data: Liquidacion = {
            id: item.id,
            fecha: item.fecha,
            ramo: item.ramo,
            monto: item.monto,
            montoPetro: item.montoPetro,
            aprobado: response.data.solicitud.aprobado,
            pagado: response.data.solicitud.pagado,
            recibo: item.recibo,
            certificado: item.certificado,
            estado: response.data.solicitud.estado,
          };
          if (!isECODEBA) changeTaxData(data.id, data);
        });
        if (response.data.solicitud.recibo) {
          const win = window.open(response.data.solicitud.recibo, '_blank');
          win?.focus();
        }
      }
    } catch (e) {
      message.error(
        e.response?.data?.message
          ? (e.response.data.message as string).includes('referencia')
            ? 'La referencia bancaria ya ha sido utilizada.'
            : e.response.data.message
          : 'Error al crear el pago'
      );
    } finally {
      setLoading(false);
      complete();
    }
  };

  const getFineTotals = () =>
    parseFloat(
      selectedReq?.multas
        ?.map((r) => round(+r.monto || +r.montoPetro * petro, 2))
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const getLiqsTotal = () =>
    parseFloat(
      selectedReq?.liquidaciones
        .map((l) => round(+l.monto || +l.montoPetro * petro, 2))
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const getLiqsAETotal = () =>
    parseFloat(
      selectedReq?.liquidaciones
        .map((l) => (l.ramo?.includes('ACTIVIDADES ECONOMICAS') ? round(+l.montoPetro * petro, 2) : 0))
        .reduce((c, v) => c + v, 0)
        .toFixed(2) || '0'
    );

  const addVoucher = (e) => {
    setComprobantes([...comprobantes, e]);
  };

  const removeVoucher = (e) => {
    let newComprobantes = comprobantes.filter((f) => f.file.name !== e);
    setComprobantes(newComprobantes);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tipo de Liquidación',
      dataIndex: 'ramo',
      render: (ramo) => <span>{ramo ? ramo : 'LIQUIDACIÓN SIN RAMO'}</span>,
    },
    {
      title: 'I.V.A. Retenido',
      dataIndex: 'ramo',
      render: (ramo, record) =>
        ramo === 'SERVICIOS MUNICIPALES' ? <b>{record.esAgenteSENIAT ? '75%' : '0%'}</b> : <span>No Aplica</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) =>
        fecha ? (
          <span>
            {firstLetterMayus(fecha.month)} {fecha.year}
          </span>
        ) : (
          <span>Sin Fecha</span>
        ),
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(round(+(+monto || record.montoPetro * petro).toFixed(2), 2))}</span>,
    },
  ];

  const fineColumns: ColumnsType<any> = [
    {
      title: 'Motivo',
      dataIndex: 'descripcion',
      render: (desc) => <span>{desc || 'MULTA VIGENTE'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (
        <span>
          {firstLetterMayus(fecha.month)} {fecha.year}
        </span>
      ),
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(round(+(+monto || record.montoPetro * petro).toFixed(2), 2))}</span>,
    },
  ];

  const interestColumn: ColumnsType<any> = [
    {
      title: 'Motivo',
      dataIndex: 'descripcion',
      render: (desc) => <span>{desc || 'Intereses moratorias sobre actividad economica'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (
        <span>
          {firstLetterMayus(fecha.month)} {fecha.year}
        </span>
      ),
    },
    {
      title: 'Monto (MMV)',
      dataIndex: 'montoPetro',
      align: 'center',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(_petro).toFixed(8)} <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: 'Monto en Bs.',
      dataIndex: 'monto',
      render: (monto, record) => <span>Bs. {formatCurrency(round(+(+monto || record.montoPetro * petro).toFixed(2), 2))}</span>,
    },
  ];

  const AEcolumns: ColumnsType<any> = [
    {
      title: 'Aforo',
      dataIndex: 'aforo',
      width: '40%',
      render: (id) => <span>{aforos.find((ae) => ae.id === id)?.descripcion}</span>,
    },
    {
      title: 'Alicuota',
      dataIndex: 'aforo',
      key: 'alicuota',
      render: (id) => <span>{aforos.find((ae) => ae.id === id)?.alicuota} %</span>,
    },
    {
      title: 'Monto Declarado',
      dataIndex: 'montoDeclarado',
      render: (md) => <span>Bs. {formatCurrency(+md)}</span>,
    },
    {
      title: 'Impuestos',
      dataIndex: 'montoCobrado',
      render: (mc) => <span>Bs. {formatCurrency(+mc)}</span>,
    },
  ];

  const IUcolumns: ColumnsType<any> = [
    {
      title: 'Código Catastral',
      dataIndex: 'codCat',
      width: '40%',
      render: (codCat) => (codCat ? <span>{codCat}</span> : <span>Inmueble sin Información Catastral</span>),
    },
    {
      title: 'Monto a Pagar',
      dataIndex: 'monto',
      render: (md) => <span>Bs. {formatCurrency(+md.toFixed(2))}</span>,
    },
  ];

  // const handleDiscount = (daysNumber) => {
  //   setDays(daysNumber);
  // };

  const downloadTicket = async (id: number) => {
    if (!downloading) {
      setDownloading(true);
      const complete = message.loading('Generando recibo...');
      try {
        const response = await axios.post(`${server}/settlements/receipt/${id}/application`, null, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        message.success('Recibo generado de manera exitosa.');
        window.open(response.data.url, '_blank');
      } catch (e) {
        message.error('Error al generar recibo para la solicitud.');
      } finally {
        setDownloading(false);
        complete();
      }
    }
  };

  const downloadCertificate = async () => {
    const complete = message.loading('Generando Certificado...');
    setDownloading(true);
    try {
      let data: any = {
        ...selectedReq,
        liquidaciones: selectedReq?.liquidaciones
          .filter((l) => l.ramo?.startsWith('ACTIVIDADES ECONOMICAS'))
          .map((l) => {
            return {
              ...l,
              monto: +formatCurrency(round(+(+l.montoPetro * +petro).toFixed(2), 2)),
              desglose: l.desglose?.map((desglose) => {
                return { ...desglose, ...ae.activities.find((a) => a.id === desglose.aforo) };
              }),
            };
          }),
      };

      let montoTotal = data?.liquidaciones?.map((l) => +l.monto).reduce((p, c) => p + c, 0);
      let montoPetroTotal = data?.liquidaciones?.map((l) => +l.montoPetro).reduce((p, c) => p + c, 0);

      data.monto = formatCurrency(montoTotal || 0);
      data.montoPetro = formatCurrency(montoPetroTotal || 0);

      const response = await axios.post(`${server}/activities/statementReconciliationAE`, data, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });

      setDownloading(false);
      complete();

      let pdfWindow = window.open('');
      pdfWindow?.document.write(
        `<iframe name="constanciaDeclaracionAE" src="data:application/pdf;base64,${encodeURI(
          response.data
        )}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;"></iframe>`
      );
    } catch (e) {
      message.error('Error al generar el certificado');
      setDownloading(false);
      complete();
    }
  };

  //a
  const applyDiscount = async () => {
    const { rebaja, observacion } = await form.validateFields();
    setLoading(true);
    const complete = message.loading('Creando pago para la declaración de impuestos');
    try {
      const solicitud = { montoRebajado: +Number(rebaja / petro).toFixed(8), observacion };
      const response = await axios.patch(
        `${server}/settlements/rebate/${selectedReq?.id}`,
        { solicitud },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success(response?.data?.message || 'Rebaja aplicada de manera exitosa!');
      rebateRequest(response.data.solicitud);
      history.push('/dashboard/impuestos/pendientes');
    } catch (e) {
      message.error(e.response?.data?.message ? e.response.data.message : 'Error al aplicar rebaja');
    } finally {
      setLoading(false);
      complete();
    }
  };

  // const reduceAmount = (record) => record ? (record.interesMoratorio - (days)) : 0;

  const AEs = selectedReq?.liquidaciones.filter((e) => e.ramo?.includes('ACTIVIDADES ECONOMICAS') && e.desglose);
  const IUs = selectedReq?.liquidaciones.filter((e) => e.ramo?.includes('PROPIEDAD INMOBILIARIA') && e.desglose);

  const getPanelSubtitle = (month, year) => (
    <span>
      {firstLetterMayus(month)} {year}
    </span>
  );

  return (
    <Card
      style={{ height: '100%' }}
      title='Pagar Impuestos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', padding: '0px 40px' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Typography.Text strong>Razón Social: {selectedReq?.contribuyente.razonSocial}</Typography.Text>
        <Typography.Text strong>
          Documento: {selectedReq?.tipoDocumento}-{selectedReq?.documento}
          {selectedReq?.referenciaMunicipal && (
            <React.Fragment>
              <br />
              R.I.M: {selectedReq?.referenciaMunicipal}
            </React.Fragment>
          )}
        </Typography.Text>
      </div>
      {selectedReq?.tipo === 'RETENCION' && (
        <Alert
          banner
          type='warning'
          message='Una vez declarada la retención debe cancelarla de inmediato, de no hacerlo puede estar expuesto a las multas correspondientes.'
        />
      )}
      {selectedReq?.estado === 'finalizado' && (
        <>
          <Row gutter={24}>
            <Divider orientation='left'>
              <Typography.Title level={4} style={{ marginLeft: 0 }}>
                Recibo
              </Typography.Title>
            </Divider>
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadTicket(selectedReq?.id || 0)}
              >
                Descargar Recibo
              </Button>
            </Col>
          </Row>
        </>
      )}
      {(AEs?.length ?? 0) > 0 && (
        <>
          <Row gutter={24}>
            <Divider orientation='left'>
              <Typography.Title level={4} style={{ marginLeft: 0 }}>
                Constancia de declaración de Actividad Económica
              </Typography.Title>
            </Divider>
            <Col>
              <Button
                type='primary'
                style={{ marginBottom: 10 }}
                loading={downloading}
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadCertificate()}
              >
                Descargar Certificado
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Row gutter={24}>
        <Divider orientation='left'>
          <Typography.Title level={4} style={{ marginLeft: 0 }}>
            Liquidaciones de la Declaración
          </Typography.Title>
        </Divider>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={selectedReq?.liquidaciones.map((l, key) => {
              l.key = key;
              return l;
            })}
            bordered
            pagination={false}
          />
        </Col>
        <Col xl={12} xs={0} />
        <Col xl={12} xs={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>Bs. {formatCurrency(getLiqsTotal())}</Descriptions.Item>
          </Descriptions>
        </Col>
        {(selectedReq?.multas?.length || -1) > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Multas Pendientes</Typography.Title>
            </Divider>
            <Col span={24}>
              <Table
                columns={fineColumns}
                dataSource={
                  selectedReq?.multas?.map((r, key) => {
                    r.key = key;
                    return r;
                  }) || []
                }
                bordered
                pagination={false}
              />
            </Col>
            <Col xl={12} xs={0} />
            <Col xl={12} xs={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total'>Bs. {formatCurrency(getFineTotals())}</Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {AEs && AEs.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Declaración de Actividades Económicas</Typography.Title>
            </Divider>
            <Collapse style={{ width: '100%' }} accordion>
              {AEs.map((ae, key) => (
                <Collapse.Panel key={key} header={getPanelSubtitle(ae.fecha.month, ae.fecha.year)}>
                  <Row>
                    <Col span={24}>
                      <Table columns={AEcolumns} pagination={false} rowKey='aforo' bordered dataSource={ae.desglose} />
                    </Col>
                  </Row>
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {IUs && IUs.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Inmuebles Urbanos</Typography.Title>
            </Divider>
            <Collapse style={{ width: '100%' }} accordion>
              {IUs.map((iu, key) => (
                <Collapse.Panel key={key} header={getPanelSubtitle(iu.fecha.month, iu.fecha.year)}>
                  <Table columns={IUcolumns} pagination={false} rowKey='codCat' bordered dataSource={iu.desglose} />
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
        {selectedReq?.interesMoratorio && selectedReq.interesMoratorio.intereses.length > 0 && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Intereses Moratorios</Typography.Title>
            </Divider>
            <Col span={24}>
              <Table
                columns={interestColumn}
                dataSource={
                  selectedReq?.interesMoratorio.intereses?.map((r, key) => {
                    r.key = key;
                    return r;
                  }) || []
                }
                bordered
                pagination={false}
              />
            </Col>
            <Col span={24}>
              <Descriptions bordered>
                <Descriptions.Item label='Total de Intereses'>
                  Bs. {formatCurrency(round(+(selectedReq.interesMoratorio.totalIntereses * petro).toFixed(2), 2))}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </>
        )}
        {/* {canReduce.includes(auth.user?.institucion?.cargo?.id || 0) && 
        <>
          <Divider orientation='left' style={{ marginLeft: 0 }}>
            <Typography.Title level={4}>Rebaja de Intereses Moratorios</Typography.Title>
          </Divider>
          <Col span={24} style={{marginTop:'20px'}}>
            <Slider onChange={(e)=> handleDiscount(e)} marks={{0:'0 días', 5:'5 días'}} style={{width:'50%'}} max={5} min={0} />
          </Col>
        </>} */}
        <Divider orientation='left' style={{ marginLeft: 0 }}>
          <Typography.Title level={4}>Total a Pagar</Typography.Title>
        </Divider>
        <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label='Total'>
              Bs.{' '}
              {formatCurrency(
                round(
                  +parseFloat(
                    selectedReq?.monto
                      ? selectedReq?.monto + latePayFine
                      : (+((Number(selectedReq?.montoPetro) || 0) * petro + latePayFine) as unknown as string) || '0'
                  ).toFixed(2),
                  2
                )
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col style={{ marginTop: '20px' }}>
          <Button onClick={() => history.goBack()}>Atrás</Button>
        </Col>
        {(parseFloat(selectedReq?.creditoFiscal || '0') > 0 || parseFloat(selectedReq?.creditoFiscalRetencion || '0') > 0) && (
          <>
            <Divider orientation='left' style={{ marginLeft: 0 }}>
              <Typography.Title level={4}>Credito Fiscal</Typography.Title>
            </Divider>
            <Col span={24}>
              <Descriptions bordered column={1}>
                {parseFloat(selectedReq?.creditoFiscal || '0') > 0 && (
                  <Descriptions.Item label='Disponible por Compensación'>
                    Bs. {formatCurrency(parseFloat(selectedReq?.creditoFiscal || '0'))}
                  </Descriptions.Item>
                )}
                {parseFloat(selectedReq?.creditoFiscalRetencion || '0') > 0 && (
                  <Descriptions.Item label='Disponible por Retención'>
                    Bs. {formatCurrency(parseFloat(selectedReq?.creditoFiscalRetencion || '0'))}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
          </>
        )}
      </Row>
      {canDiscount.includes(auth.user?.institucion?.cargo?.id || 0) &&
      selectedReq?.liquidaciones.some(
        (l) => l.ramo === 'ACTIVIDADES ECONOMICAS COMERCIALES, INDUSTRIALES, DE SERVICIO Y SIMILARES'
      ) &&
      !selectedReq?.rebajado ? (
        <>
          {discount ? (
            <>
              <Divider orientation='left' style={{ marginLeft: -10 }}>
                <Typography.Title level={4}>
                  Deducciones De Retenciones Aplicadas Para Liquidación de Actividades Económicas
                </Typography.Title>
              </Divider>
              <Form
                layout='vertical'
                style={{ marginTop: 20 }}
                form={form}
                initialValues={{ metodoPago: 'TRANSFERENCIA' }}
                onValuesChange={(c, v) => {
                  form.setFieldsValue(v);
                  setData(v);
                }}
              >
                <Row>
                  <Col>
                    <Form.Item
                      label='Monto deducciones de retenciones aplicadas'
                      name='rebaja'
                      rules={[
                        { required: true, message: 'Por favor ingrese el monto.' },
                        {
                          validator: (rule, value) =>
                            value && getLiqsAETotal() < value
                              ? Promise.reject(
                                  'El monto deducciones de retenciones aplicadas no puede ser mayor al monto total de las Actividades Economicas.'
                                )
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                        min={0}
                        step={1}
                        style={{ width: '100%' }}
                        placeholder='Monto'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ paddingLeft: 16 }} gutter={16}>
                  <Col>
                    <Form.Item
                      label='Observación'
                      rules={[{ required: true, message: 'Por favor ingresar observación!' }]}
                      name='observacion'
                    >
                      <Input.TextArea maxLength={250} style={{ resize: 'none' }} placeholder='Observación' />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Col span={24}>
                <Descriptions column={1} bordered>
                  <Descriptions.Item span={1} label='Monto Total de Actividades Economicas'>
                    Bs. {formatCurrency(getLiqsAETotal())}
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label='Monto Despues de las deducciones de retenciones aplicadas'>
                    Bs. {formatCurrency(getLiqsAETotal() - (form.getFieldValue('rebaja') || 0))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    span={1}
                    label='Monto Total de liquidacion despues de las deducciones de retenciones aplicadas'
                  >
                    Bs.{' '}
                    {formatCurrency(
                      getLiqsTotal() +
                        getFineTotals() +
                        +(selectedReq?.interesMoratorio?.totalIntereses || 0) -
                        (form.getFieldValue('rebaja') || 0)
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
                <Col>
                  <Button onClick={() => history.goBack()}>Atrás</Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => applyDiscount()}
                    icon={<MinusCircleOutlined />}
                  >
                    Aplicar
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => history.goBack()}>Atrás</Button>
              </Col>
              {selectedReq.liquidaciones?.some((l) => l.ramo?.includes('ACTIVIDADES ECONOMICAS')) && (
                <Col>
                  <Button type='primary' onClick={() => setDiscount(true)} icon={<MinusCircleOutlined />}>
                    Deducciones de retenciones aplicadas
                  </Button>
                </Col>
              )}
            </Row>
          )}
        </>
      ) : (
        !(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) && (
          <Row gutter={12} justify='start' style={{ marginTop: 10 }}>
            <Col>
              <Button onClick={() => history.goBack()}>Atrás</Button>
            </Col>
          </Row>
        )
      )}
      {(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
        selectedReq?.estado === 'ingresardatos' && (
          <>
            <Divider orientation='left' style={{ marginLeft: -10 }}>
              <Typography.Title level={4}>Datos de Pago</Typography.Title>
            </Divider>
            {latePayFine > 0 ? (
              <Typography.Text strong>
                La liquidación de impuestos tiene un total de de Bs.{' '}
                {formatCurrency(
                  round(
                    +parseFloat(
                      selectedReq?.monto
                        ? String(Number(selectedReq?.monto) + latePayFine)
                        : (+(((Number(selectedReq?.montoPetro) || 0) + latePayFine) * petro) as unknown as string) || '0'
                    ).toFixed(2),
                    2
                  )
                )}
                . El total incluye un adicional de BS. {formatCurrency(Number(latePayFine.toFixed(2)))}
                por pago tardío de la declaración. . El monto de la(s) transaccion(es) debe ser igual o mayor al total de pagar y
                deben ser realizadas desde el mismo banco.
              </Typography.Text>
            ) : (
              <Typography.Text strong>
                La liquidación de impuestos tiene un total de de Bs.{' '}
                {formatCurrency(
                  round(
                    +parseFloat(
                      selectedReq?.monto
                        ? selectedReq?.monto + latePayFine
                        : ((+((Number(selectedReq?.montoPetro) || 0) + latePayFine) * petro) as unknown as string) || '0'
                    ).toFixed(2),
                    2
                  )
                )}
                . El monto de la(s) transaccion(es) debe ser igual o mayor al total de pagar y deben ser realizadas desde el mismo
                banco.
              </Typography.Text>
            )}
            <Form
              layout='vertical'
              style={{ marginTop: 20 }}
              form={form}
              initialValues={{ metodoPago: 'TRANSFERENCIA' }}
              onValuesChange={(c, v) => {
                form.setFieldsValue(v);
                setData(v);
              }}
            >
              {width >= 992 && <Col span={16} />}
              {auth.user?.tipoUsuario !== 4 ? (
                <MultipleTransactions
                  selectedReqType={selectedReq?.tipo}
                  selectedInstitution={selectedInstitution}
                  form={form}
                  data={data}
                  maxCred={parseFloat(selectedReq?.creditoFiscal || '0')}
                  maxCredR={parseFloat(selectedReq?.creditoFiscalRetencion || '0')}
                />
              ) : (
                <MultipleWireTransfers
                  selectedInstitution={selectedInstitution}
                  form={form}
                  addVoucher={(e) => addVoucher(e)}
                  removeVoucher={(e) => removeVoucher(e)}
                />
              )}
            </Form>
            <Row>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Total Ingresado</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item label='Total Ingresado'>
                    Bs.{' '}
                    {formatCurrency(
                      data.pagos
                        ?.filter((p) => p)
                        ?.map((p) => p.costo)
                        ?.reduce((c, v) => c + v, 0) || 0
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Divider orientation='left' style={{ marginLeft: 0 }}>
                <Typography.Title level={4}>Monto Restante</Typography.Title>
              </Divider>
              <Col xl={24} xs={24}>
                <Descriptions bordered>
                  <Descriptions.Item
                    label={
                      (data.pagos
                        ?.filter((p) => p)
                        ?.map((p) => p.costo)
                        ?.reduce((c, v) => c + v, 0) || 0) >
                      +parseFloat(
                        selectedReq?.monto
                          ? selectedReq?.monto + latePayFine
                          : ((+((Number(selectedReq?.montoPetro) || 0) + latePayFine) * petro) as unknown as string) || '0'
                      ).toFixed(2)
                        ? 'Total a Favor'
                        : 'Total Restante'
                    }
                    style={{
                      color:
                        (data.pagos
                          ?.filter((p) => p)
                          ?.map((p) => p.costo)
                          ?.reduce((c, v) => c + v, 0) || 0) >
                        +parseFloat(
                          selectedReq?.monto
                            ? selectedReq?.monto + latePayFine
                            : ((+((Number(selectedReq?.montoPetro) || 0) + latePayFine) * petro) as unknown as string) || '0'
                        ).toFixed(2)
                          ? '#86E92B'
                          : 'red',
                    }}
                  >
                    Bs.{' '}
                    {formatCurrency(
                      +(
                        ((data.pagos
                          ?.filter((p) => p)
                          ?.map((p) => p.costo)
                          ?.reduce((c, v) => c + v, 0) || 0) -
                          +parseFloat(
                            selectedReq?.monto
                              ? selectedReq?.monto + latePayFine
                              : ((+((Number(selectedReq?.montoPetro) || 0) + latePayFine) * petro) as unknown as string) || '0'
                          )) *
                        ((data.pagos
                          ?.filter((p) => p)
                          ?.map((p) => p.costo)
                          ?.reduce((c, v) => c + v, 0) || 0) >
                        +parseFloat(
                          selectedReq?.monto
                            ? selectedReq?.monto + latePayFine
                            : ((+((Number(selectedReq?.montoPetro) || 0) + latePayFine) * petro) as unknown as string) || '0'
                        )
                          ? 1
                          : -1)
                      ).toFixed(2)
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row gutter={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }}>
              <Col>
                <Button onClick={() => history.goBack()}>Atrás</Button>
              </Col>
              <Col>
                {selectedReq?.estado === 'ingresardatos' && (
                  <Button
                    type='primary'
                    loading={loading}
                    style={{ marginTop: 15 }}
                    onClick={() => pay()}
                    icon={<CreditCardFilled />}
                  >
                    Pagar
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )}
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  requests: state.req.requests,
  requestsEcodeba: state.req.requestsEcodeba,
  banks: state.bk.banks,
  institutions: state.inst.institutions,
  auth: state.auth,
  ae: state.ae,
});

export default connect(mapStateToProps, { changeRequestStatus, changeTaxData, rebateRequest, changeRequestStatusEcodeba })(
  PaySpecificTax
);

interface PaySpecificTaxProps {
  thm: States.ThemeColors;
  requests: States.Requests['requests'];
  requestsEcodeba: States.Requests['requestsEcodeba'];
  banks: States.Banks['banks'];
  institutions: States.Institutions['institutions'];
  auth: States.Auth;
  ae: States.AES;
  changeRequestStatus: (status: string, id: number) => void;
  changeRequestStatusEcodeba: (status: string, id: number) => void;
  changeTaxData: (id: number, data: Liquidacion) => void;
  rebateRequest: (req: Request) => void;
}
