import { Alert, DatePicker, Divider, Form, Input, Row, Select, Typography } from 'antd';
import { Col } from 'antd/es/grid';
import React, { useState } from 'react';

const FormCleaningFee = () => {
  const [tipoDocumento, setTipoDocumento] = useState('');
  const tipoAddon = (
    <Form.Item name='tipoDocumento' style={{ padding: 0, margin: 0 }}>
      <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <Row gutter={24}>
        {/* <Col xl={7} xs={24}>
          <Form.Item
            label='Tipo de Contribuyente'
            name='tipoContribuyente'
            rules={[{ required: true, message: 'Debe indicar el tipo de contribuyente' }]}
          >
            <Select placeholder='Tipo de Contribuyente'>
              <Select.Option value='NATURAL'>Natural</Select.Option>
              <Select.Option value='JURIDICO'>Jurídico</Select.Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Divider orientation='left'>
          <Typography.Title level={4}>Datos del Contribuyente</Typography.Title>
        </Divider>
        <Col xs={24} xl={7}>
          <Form.Item
            label='Documento de Identidad'
            name='documento'
            rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
          >
            <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
          </Form.Item>
        </Col>
        <Col xs={24} xl={7}>
          <Form.Item label='R.I.M.' name='rim'>
            <Input placeholder='R.I.M.' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Alert
            type='info'
            message='Verifique la dirección del inmueble del cual esta pagando el servicio de aseo. En caso de ser incorrecta ingrese una dirección valida.'
          />
        </Col>
        <Col span={24}>
          <Form.Item label='Dirección' name='direccion' rules={[{ required: true, message: 'Debe ingresar una dirección' }]}>
            <Input.TextArea placeholder='Dirección' />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={10}>
          <Form.Item label='Mes a pagar' name='periodo' rules={[{ required: true, message: 'Debe seleccionar el mes a pagar' }]}>
            <DatePicker picker='month' style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default FormCleaningFee;
