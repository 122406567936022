import React, { useEffect, useState } from 'react';
import { createFee, getAllFee } from '../../../services/CleaningFee';
import { useForm } from 'antd/lib/form/util';
import { useDispatch, useSelector } from 'react-redux';
import { State, Taxpayer } from 'sigt';
import { Button, Col, Descriptions, Form, Row, Select, message } from 'antd';
import moment, { Moment } from 'moment';
import FormCleaningFee from './FormCleaningFee';
import { formatCurrency } from '../../../utils/formatNumber';
import { addRequestEcodeba } from '../../../redux/actions/requests';

const CommercialCleaning: React.FC<CommercialCleaningProps> = ({ taxpayer }) => {
  const [comercialGroup, setComercialGroup] = useState<ComercialGroup[] | null>(null);
  const [comercialActivity, setComercialActivity] = useState<ComercialActivity[] | null>(null);
  const [dataSelected, setDataSelected] = useState<DataSelected>({ actividad: null, grupo: null });
  const [loading, setLoading] = useState(false);
  const [periodo, setPeriodo] = useState<Moment | null>(null);

  const dispatch = useDispatch();

  const [form] = useForm();
  const auth = useSelector((state: State) => state.auth);
  const IVA = 0.16;

  useEffect(() => {
    if (auth) {
      getAllFee(auth?.token ?? '').then((res) => {
        setComercialGroup(res.data.comercialGroup);
        setComercialActivity(res.data.comercialActivity);
      });
      setInitialValues();
    }
    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (taxpayer) setInitialValues();
    // eslint-disable-next-line
  }, [taxpayer]);

  const handleDataSelected = (field: string, value: string | number | null) => {
    setDataSelected((state: DataSelected) => ({ ...state, [field]: value }));
  };

  const setInitialValues = () => {
    if (auth?.user?.tipoUsuario === 4) {
      form.setFieldsValue({
        direccion: auth?.user?.contribuyente?.direccion ?? '',
        documento: auth?.user?.contribuyente?.documento ?? '',
        tipoDocumento: auth?.user?.contribuyente?.tipoDocumento ?? '',
      });
    } else {
      form.setFieldsValue({
        direccion: taxpayer?.direccion ?? '',
        documento: taxpayer?.documento ?? '',
        tipoDocumento: taxpayer?.tipoDocumento ?? '',
      });
    }
  };

  const handleSelectParent = (value) => {
    form.setFieldsValue({
      grupo: value,
      actividad: null,
    });
    handleDataSelected('actividad', null);
  };

  const submit = async () => {
    try {
      setLoading(true);

      const values = await form.validateFields();

      const data = {
        idTarifa: values.actividad,
        documento: values.documento,
        tipoDocumento: values.tipoDocumento,
        rim: values.rim,
        fecha: {
          month: moment(values.periodo).locale('es').format('MMMM'),
          year: moment(values.periodo).locale('es').format('YYYY'),
        },
        direccion: values.direccion,
        iva: periodoValido() ? IVA : 0,
      };

      const response = await createFee('comercialActivity', data, auth?.token ?? '');
      if (response.status === 200) {
        message.success('Liquidacion procesada exitosamente.');
        form.resetFields();
        setDataSelected({ actividad: null, grupo: null });
        setInitialValues();
        dispatch(addRequestEcodeba(response.data.solicitud));
      }
    } catch (e) {
      message.error(e.response?.data?.error || e.response?.data?.message || 'Error al momento procesar la liquidacion');
    } finally {
      setLoading(false);
    }
  };

  const periodoValido = () => {
    return periodo && periodo.isSameOrAfter(moment('2024-03'));
  };

  return (
    <Row gutter={24}>
      <Form
        form={form}
        layout='vertical'
        scrollToFirstError
        onFinish={() => submit()}
        onValuesChange={(c, v) => {
          form.setFieldsValue(v);
          setPeriodo(v?.periodo);
        }}
      >
        <Col span={24}>
          <Row gutter={24}>
            <Col span={10}>
              <Form.Item label='Grupo' name='grupo' rules={[{ required: true, message: 'Debe seleccionar un grupo' }]}>
                <Select
                  onChange={(value: number) => {
                    handleSelectParent(value);
                    handleDataSelected('grupo', value);
                  }}
                >
                  {comercialGroup &&
                    comercialGroup.map((p) => (
                      <Select.Option key={p.descripcion} value={p.id}>
                        {p.descripcion}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label='Actividad'
                name='actividad'
                rules={[{ required: true, message: 'Debe seleccionar una actividad' }]}
              >
                <Select onChange={(value: number) => handleDataSelected('actividad', value)}>
                  {comercialActivity &&
                    comercialActivity
                      .filter((p) => p.idComunidadGrupo === dataSelected.grupo)
                      .map((p) => (
                        <Select.Option key={p.descripcion} value={p.id}>
                          {p.descripcion}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <FormCleaningFee />

          <Descriptions>
            <Descriptions.Item label='Tarifa'>
              {comercialActivity &&
                `${formatCurrency(comercialActivity?.find((p) => p.id === dataSelected.actividad)?.tarifa ?? 0)} MMV`}
            </Descriptions.Item>
            <Descriptions.Item label='IVA'>
              {comercialActivity
                ? periodoValido()
                  ? formatCurrency(+(comercialActivity?.find((p) => p.id === dataSelected.actividad)?.tarifa ?? 0) * IVA)
                  : 0
                : 0}
            </Descriptions.Item>
            <Descriptions.Item label='Monto total'>
              {comercialActivity
                ? periodoValido()
                  ? formatCurrency(
                      +(comercialActivity?.find((p) => p.id === dataSelected.actividad)?.tarifa ?? 0) +
                        +(comercialActivity?.find((p) => p.id === dataSelected.actividad)?.tarifa ?? 0) * IVA
                    )
                  : formatCurrency(+(comercialActivity?.find((p) => p.id === dataSelected.actividad)?.tarifa ?? 0))
                : 0}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Button type='primary' loading={loading} onClick={form.submit}>
            Generar
          </Button>
        </Col>
      </Form>
    </Row>
  );
};

export default CommercialCleaning;

interface CommercialCleaningProps {
  taxpayer: Taxpayer | null | undefined;
}
interface DataSelected {
  grupo: number | null;
  actividad: number | null;
}

interface ComercialGroup {
  id: number;
  descripcion: string;
}

interface ComercialActivity {
  id: number;
  idComunidadGrupo: number;
  tarifa: number;
  descripcion: string;
}
