import React from 'react';
import { Select } from 'antd';

const TaxpayerSocietyType: React.FC<TaxpayerSocietyTypeProps> = ({ value, onChange, placeholder }) => {
  return (
    <Select value={value} onChange={onChange} placeholder={placeholder}>
      <Select.Option value='Compañía Anónima'>Compañía Anónima</Select.Option>
      <Select.Option value='Sociedad Anónima'>Sociedad Anónima</Select.Option>
      <Select.Option value='Firma Personal'>Firma Personal</Select.Option>
      <Select.Option value='Sociedad de Responsabilidad Limitada'>Sociedad de Responsabilidad Limitadas</Select.Option>
    </Select>
  );
};

export default TaxpayerSocietyType;

interface TaxpayerSocietyTypeProps {
  value?: any;
  onChange?: (e: any) => any;
  placeholder: string;
}
