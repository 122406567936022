import React, { useState, useRef } from 'react';
import { Row, Col, Button, Card, Modal } from 'antd';
import { DeleteOutlined, DownloadOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';

const FileImageUpload = ({ onUpload, onRemove, download = false, textBtn = 'Subir Archivo', accept = 'image/*,.pdf' }) => {
  const [file, setFile] = useState<any>(null);
  const fileRef = useRef<any>(null);
  const filePreviewRef = useRef<any>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [showUpload, setShowUpload] = useState(true);

  const handleUpload = async (target) => {
    const url = await getBase64(target.files[0]);
    const data = { file: target.files[0], url };
    setFile(data);
    setShowUpload(false);
    handleFiles(data);
    onUpload(data);
  };

  const handleFiles = (data) => {
    if (data.file.type.includes('image')) {
      filePreviewRef.current.setAttribute('src', data.url || '');
      return;
    }
    if (data.file.type.includes('pdf')) {
      filePreviewRef.current.setAttribute('src', require('../../../assets/images/pdf.png'));
      filePreviewRef.current.style.setProperty('width', '80px');
      filePreviewRef.current.style.setProperty('height', '80px');
      filePreviewRef.current.style.setProperty('margin', '10px');
      return;
    } else {
      filePreviewRef.current.setAttribute('src', require('../../../assets/images/file.png'));
      filePreviewRef.current.style.setProperty('width', '80px');
      filePreviewRef.current.style.setProperty('height', '80px');
      filePreviewRef.current.style.setProperty('margin', '10px');
      return;
    }
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleRemoveFile = () => {
    let fileName = file.file.name;

    setPreviewImage('');
    setPreviewVisible(false);
    setPreviewTitle('');
    setShowUpload(true);
    setFile(false);
    onRemove(fileName);
  };

  const handlePreview = async (file) => {
    if (file.file.type.includes('image')) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewVisible(true);
      setPreviewTitle(file.file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    }
  };

  const getBase64 = (file): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const getLastItemURL = (url) => url.substring(url.lastIndexOf('/') + 1);

  return (
    <>
      <Row>
        {showUpload && (
          <>
            <input
              accept={accept}
              onChange={({ target }) => handleUpload(target)}
              ref={fileRef}
              type='file'
              style={{ display: 'none' }}
            />
            <Button
              style={{ marginTop: 5, marginBottom: 5 }}
              type='primary'
              icon={<UploadOutlined />}
              onClick={() => fileRef.current.click()}
            >
              {textBtn}
            </Button>
          </>
        )}
      </Row>

      <Row gutter={[16, 16]}>
        {file && (
          <Col>
            <Card
              actions={[
                download ? (
                  <a href={file.url} download={getLastItemURL(file.url)}>
                    Descargar <DownloadOutlined />
                  </a>
                ) : (
                  <Button
                    type='link'
                    onClick={() => handleRemoveFile()}
                    style={{ textAlign: 'right', width: '100%', color: 'red' }}
                  >
                    Eliminar
                    <DeleteOutlined />
                  </Button>
                ),
                file?.file.type.includes('image') && (
                  <Button type='link' onClick={() => handlePreview(file)} style={{ textAlign: 'right', width: '100%' }}>
                    {' '}
                    Preview
                    <EyeOutlined />
                  </Button>
                ),
              ]}
              bodyStyle={{ borderTop: '1px solid #00000026' }}
              hoverable
              style={{ width: 220 }}
              cover={<img ref={filePreviewRef} />}
            >
              {file?.file?.name ? file?.file?.name : file.includes('pdf') ? `Pdf` : `Imagen`}
            </Card>
          </Col>
        )}
        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
          <img style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Row>
    </>
  );
};

export default FileImageUpload;
