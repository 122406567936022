import S3 from 'aws-sdk/clients/s3';

const s3Client = new S3({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

export async function getFileFromS3(fileName: string) {
  try {
    const params: S3.Types.GetObjectRequest = {
      Bucket: process.env.REACT_APP_BUCKET_NAME ?? '',
      Key: fileName,
    };

    const response = await s3Client.getObject(params).promise();
    return response.Body as Buffer;
  } catch (error) {
    console.error('Error al obtener el archivo de S3:', error);
    throw error;
  }
}
