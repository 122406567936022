import { Avatar, Button, Typography, message as AntdMessage } from 'antd';
import React, { useState } from 'react';
import styles from './virtualAssistant.module.css';
import { State } from 'sigt';
import { useSelector } from 'react-redux';
import { DislikeFilled, DislikeOutlined, DownloadOutlined, LikeFilled, LikeOutlined, UserOutlined } from '@ant-design/icons';
import logoSUT from './image/SUT_icon.png';
import { IMessage } from '.';
import { messageRating } from './services';
export default function Message({ message, role, fileInfo, fileToRender, prompt }: IMessage) {
  const thm = useSelector((state: State) => state.thm);
  const [like, setLike] = useState(false);
  const [disLike, setDisLike] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTime = () => {
    const date = new Date();
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // las 12 en punto son las 12 pm
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  const downloadFile = () => {
    try {
      if (!(fileToRender && fileInfo)) return;
      window.open(fileToRender, '_blank');
    } catch (error) {
      console.log('downloadFile', error);
    }
  };

  const handleMessageRating = async (is_good, rating: 'LIKE' | 'DISLIKE') => {
    try {
      setLoading(true);
      const response = await messageRating({ prompt, is_good, ai_answer: message });
      if (response.status === 200) {
        rating === 'LIKE' ? setLike(!like) : setDisLike(!disLike);
        AntdMessage.success('Rating enviado.');
      }
    } catch (error) {
      AntdMessage.error('No podemos procesar el rating, inténtalo de nuevo.');
      console.log('handleMessageRating', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {role === 'USER' ? (
        <div style={{ justifyContent: 'flex-end' }} className={styles.container}>
          <div className={styles.containerMessage} style={{ alignItems: 'end' }}>
            <Typography.Text
              className={styles.message}
              style={{
                borderRadius: '5px 15px 5px 5px',
              }}
            >
              {message}
            </Typography.Text>
            <Typography.Text>{getTime()}</Typography.Text>
          </div>
          <Avatar style={{ backgroundColor: thm.primaryColor }} icon={<UserOutlined />} />
        </div>
      ) : (
        <div style={{ justifyContent: 'flex-start' }} className={styles.container}>
          <Avatar src={logoSUT} />
          <div className={styles.containerMessage} style={{ alignItems: 'start' }}>
            {fileInfo?.type === 'file' && (
              <div className={styles.containerButtons}>
                <Button onClick={downloadFile} shape='circle-outline' icon={<DownloadOutlined />} />
              </div>
            )}
            {fileInfo?.type === 'image' ? (
              <img style={{ width: '60vh', height: '60vh' }} alt={fileInfo?.file_name ?? 'image'} src={fileToRender ?? ''} />
            ) : (
              <Typography.Text
                className={styles.message}
                style={{
                  borderRadius: '15px 5px 5px 5px',
                }}
              >
                {message}
                {fileInfo?.type === 'file' && (
                  <Button
                    style={{ padding: 0 }}
                    type='link'
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                  >
                    {fileInfo.file_name}
                  </Button>
                )}
              </Typography.Text>
            )}
            <Typography.Text>{getTime()}</Typography.Text>
            <div className={styles.containerBtnLike}>
              <Button
                onClick={() => handleMessageRating('True', 'LIKE')}
                disabled={like || disLike}
                shape='circle'
                icon={like ? <LikeFilled /> : <LikeOutlined />}
                loading={loading}
              />
              <Button
                onClick={() => handleMessageRating('False', 'DISLIKE')}
                disabled={like || disLike}
                shape='circle'
                icon={disLike ? <DislikeFilled /> : <DislikeOutlined />}
                loading={loading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
