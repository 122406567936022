import React, { useEffect, Fragment, useState } from 'react';
import { Form, Button, Row, Col, Modal, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { createHoliday } from '../../redux/actions/holidays';
import { State, States, Taxpayer } from 'sigt';
import handlingMessage from '../../utils/handlingMessage';
import { editContributorRim, editContributorRif } from '../../services/contributor';
import { UserOutlined, PhoneFilled } from '@ant-design/icons';
import { Utils } from '../../utils/validators';
import ParishSelect from './components/ParishSelect';
import TaxpayerSocietyType from './components/TaxpayerSocietyType';

const EditContributorForm: React.FC<EditContributorFormProps> = ({
  auth,
  modal,
  rim,
  close,
  prsh,
  taxpayer,
  editRim,
  editRif,
}) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);
  //moods
  const title = rim ? 'Editar R.I.M' : 'Editar Contribuyente';

  useEffect(() => {
    if (rim) {
      const data = taxpayer?.sucursales?.find((el) => el.id === rim);
      if (data) data.parroquia = prsh.parish.find((e) => e.id === data?.parroquia)?.nombre;
      form.setFieldsValue({ ...data });
    } else {
      form.setFieldsValue({ ...taxpayer });
    }
    // eslint-disable-next-line
  }, [taxpayer, rim]);

  const renderWrapper = () => {
    if (modal)
      return (
        <Modal title={title} footer={null} width={600} visible centered style={{ maxHeight: '700px' }} onCancel={() => close()}>
          {content}
        </Modal>
      );
  };

  const docTypeSelect = (
    <Form.Item
      noStyle
      name='tipoDocumento'
      rules={[{ required: true, message: 'Debe ingresar el tipo de documento del contribuyente' }]}
    >
      <Select>
        <Select.Option value='J'>J</Select.Option>
        <Select.Option value='G'>G</Select.Option>
        <Select.Option value='V'>V</Select.Option>
        <Select.Option value='E'>E</Select.Option>
        <Select.Option value='P'>P</Select.Option>
      </Select>
    </Form.Item>
  );

  const handleSubmit = async () => {
    setDisable(true);
    const values = await form.validateFields();
    values.id = rim ? rim : taxpayer?.id;
    const newValues = values as Taxpayer;
    handlingMessage({
      action: () => (rim ? editContributorRim(values, auth.token) : editContributorRif(values, auth.token)),
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) rim ? editRim(newValues) : editRif(newValues);
      },
    });
    setDisable(false);
  };
  const editRimContent = (
    <>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresa el R.I.M' }, { validator: Utils.validators.alphaNumeric }]}
            name='referenciaMunicipal'
            label='R.I.M'
          >
            <Input placeholder='R.I.M' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            normalize={Utils.normalize.toLower}
            rules={[{ required: true, message: 'Por favor ingresa tu correo' }, { validator: Utils.validators.email }]}
            name='email'
            label='Correo'
          >
            <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder='Correo' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar el Nombre Representante!' }]}
            name='nombreRepresentante'
            label='Nombre Representante'
          >
            <Input placeholder='Nombre Representante' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar Denominacion Comercial!' }]}
            name='denomComercial'
            label='Denominación Comercial'
          >
            <Input placeholder='Denominación Comercial' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar el tipo de sociedad!' }]}
            name='tipoSociedad'
            label='Tipo de Sociedad'
          >
            <TaxpayerSocietyType placeholder='Tipo de Sociedad' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar el Capital Suscrito!' }]}
            name='capitalSuscrito'
            label='Capital Suscrito'
          >
            <Input placeholder='Capital Suscrito' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar tu telefono!' }, { validator: Utils.validators.phoneNumber }]}
            normalize={Utils.normalize.isNumber}
            name='telefono'
            validateTrigger='onBlur'
            label='Teléfono'
          >
            <Input
              maxLength={10}
              addonBefore='+58'
              prefix={<PhoneFilled style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Teléfono'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar parroquia!' }]} name='parroquia' label='Parroquia'>
            <ParishSelect />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar la dirección!' }]} name='direccion' label='Dirección'>
            <Input placeholder='Dirección' />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='end' style={{ textAlign: 'center' }} align='middle' gutter={16}>
        <Col xs={12} md={6} xl={6}>
          <Button disabled={disable} style={{ width: '100%' }} type='primary' htmlType='submit'>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
  const editRifContent = (
    <>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar documento!' }]} name='documento' label='Documento'>
            <Input addonBefore={docTypeSelect} placeholder='Documento' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar Razon social!' }]}
            name='razonSocial'
            label='Razon social'
          >
            <Input placeholder='Razon social' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar Denominacion Comercial!' }]}
            name='denomComercial'
            label='Denominación Comercial'
          >
            <Input placeholder='Denominación Comercial' />
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar siglas!' }]} name='siglas' label='Siglas'>
            <Input placeholder='Siglas' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} md={12} xs={24}>
          <Form.Item name='parroquia' rules={[{ required: true, message: 'Debe ingresar la parroquia' }]} label='Parroquia'>
            <Select placeholder='Parroquia'>
              {prsh.parish.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.nombre}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} md={12} xs={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar sector!' }]} name='sector' label='Sector'>
            <Input placeholder='Sector' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item rules={[{ required: true, message: 'Por favor ingresar direccion!' }]} name='direccion' label='Dirección'>
            <Input placeholder='Dirección' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            rules={[{ required: true, message: 'Por favor ingresar puntoReferencia!' }]}
            name='puntoReferencia'
            label='Punto de referencia'
          >
            <Input placeholder='Punto de referencia' />
          </Form.Item>
        </Col>
      </Row>
      <Row justify='end' style={{ textAlign: 'center' }} align='middle' gutter={16}>
        <Col xs={12} md={6} xl={6}>
          <Button disabled={disable} style={{ width: '100%' }} type='primary' htmlType='submit'>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
  const content = (
    <Form onFinish={handleSubmit} form={form} layout='vertical'>
      {rim ? editRimContent : editRifContent}
    </Form>
  );

  return <Fragment>{renderWrapper()}</Fragment>;
};

const mapStateToProps = ({ auth, prsh }: State) => ({ auth, prsh });

export default connect(mapStateToProps, { createHoliday })(EditContributorForm);

interface EditContributorFormProps {
  auth: States.Auth;
  prsh: States.Parish;
  rim: string | null;
  taxpayer: Taxpayer | null;
  modal?: boolean;
  close: () => void;
  editRif: (data: Taxpayer) => void;
  editRim: (data: any) => void;
}
