import React, { useState, useEffect, useRef } from 'react';
import { States, State, DatosEnlace, Sucursal } from 'sigt';
import { connect } from 'react-redux';
import { Modal, Row, Col, Form, Input, message, Card, Steps } from 'antd';
import { useHistory } from 'react-router';
import { Utils } from '../../../utils/validators';
import axios from 'axios';
import { useWindowDimensions } from '../../../utils/hooks';
import RegistersSelect from './RegistersSelect';
import EditRegisters from './EditRegisters';
const server = process.env.REACT_APP_SERVER_URL;

const LinkAccount: React.FC<LinkAccountProps> = ({ thm, auth, inst }) => {
  const [visible, setVisble] = useState(false);
  const [loading, setLoading] = useState(false);
  const [linkingData, setLinkingData] = useState<DatosEnlace | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const [selectedRegisters, setSelectedRegisters] = useState<Sucursal[]>([]);

  const [form] = Form.useForm();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const modalRef = useRef(null);

  useEffect(() => {
    if (!inst) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, [inst]);

  useEffect(() => {
    if (!visible) setVisble(true);
    return () => {
      if (!linkingData) setVisble(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((history.location.state as any)?.datosEnlace) {
      setLinkingData((history.location.state as any)?.datosEnlace);
      setSelectedRegisters((history.location.state as any)?.datosEnlace?.sucursales || []);
      setVisble(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const authenticate = async () => {
    const values = await form.validateFields();
    const body = {
      usuario: values,
    };
    try {
      setLoading(true);
      const response = await axios.post<{ datosEnlace: DatosEnlace[] }>(`${server}/settlements/login`, body, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setLinkingData(response.data.datosEnlace[0]);
      setSelectedRegisters(response.data.datosEnlace[0]?.sucursales);
      setVisble(false);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al autenticar usuario');
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Card
        style={{ height: '100%' }}
        title='Enlace de cuenta de SAMAT'
        bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
        headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
      >
        <Row>
          <Col span={24}>
            <Steps current={current} size='small'>
              <Steps.Step title='Selección de registros' />
              <Steps.Step title='Verificación y edición de datos' />
              <Steps.Step title='Finalizado' />
            </Steps>
          </Col>
        </Row>
        {current === 0 && (
          <RegistersSelect
            selectedRegisters={selectedRegisters}
            setSelectedRegisters={setSelectedRegisters}
            linkingData={linkingData}
            setCurrent={setCurrent}
          />
        )}
        {current === 1 && (
          <EditRegisters
            form={form}
            selectedRegisters={selectedRegisters}
            setCurrent={setCurrent}
            linkingData={linkingData}
            setLinkingData={setLinkingData}
            resetCurrent={() => setCurrent(0)}
          />
        )}
      </Card>
      <Modal
        ref={modalRef}
        destroyOnClose
        visible={visible}
        onCancel={() => history.goBack()}
        title='Enlazar cuenta de SEDEMAT'
        cancelButtonProps={{ hidden: true }}
        bodyStyle={{ paddingLeft: 50, paddingRight: 50 }}
        onOk={form.submit}
        okButtonProps={{ loading }}
        closable={!loading}
        maskClosable={!loading}
        okText={loading ? 'Autenticando' : 'Autenticar'}
      >
        <Form form={form} layout='vertical' onFinish={authenticate}>
          <Row gutter={24}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <img alt='sedemat' src={require('../../../assets/images/sedematLogo.png')} style={{ width: 250 }} />
            </Col>
            <Col span={24} style={{ marginTop: 10 }}>
              <Form.Item
                label='Correo Electrónico'
                name='nombreUsuario'
                rules={[
                  { required: true, message: 'Debe ingresar su correo electrónico' },
                  { validator: Utils.validators.email },
                ]}
              >
                <Input placeholder='Correo Electrónico' />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label='Contraseña' name='password' rules={[{ required: true, message: 'Debe ingresar su contraseña' }]}>
                <Input placeholder='Contraseña' type='password' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth, inst: state.inst.institutions });

export default connect(mapStateToProps)(LinkAccount);

interface LinkAccountProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  inst: States.Institutions['institutions'];
}
