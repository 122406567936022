import React, { useState } from 'react';
import { Form, Button, Row, Col, Modal, Input } from 'antd';
import { States } from 'sigt';

const ModalWrapperForm: React.FC<ModalWrapperFormProps> = ({ title, children, close, onSubmit, observation, visible }) => {
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(false);

  const handleSubmit = async () => {
    setDisable(true);
    const values = await form.validateFields();
    await onSubmit(values);
    setDisable(false);
  };

  return (
    <Modal
      title={title}
      footer={null}
      width={600}
      visible={visible}
      centered
      style={{ maxHeight: '700px' }}
      onCancel={() => close()}
    >
      <Form onFinish={handleSubmit} form={form} layout='vertical'>
        <Row gutter={16}>
          {observation && (
            <Col span={24}>
              <Form.Item
                name='observacion'
                label='Observación'
                rules={[{ required: true, message: 'Debe colocar una observación' }]}
              >
                <Input.TextArea placeholder='Observación' />
              </Form.Item>
            </Col>
          )}
          {children}
        </Row>
        <Row justify='end' style={{ textAlign: 'center' }} align='middle' gutter={16}>
          <Col xs={12} md={6} xl={6}>
            <Button disabled={disable} style={{ width: '100%' }} type='primary' htmlType='submit'>
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalWrapperForm;

interface ModalWrapperFormProps {
  auth?: States.Auth;
  close: () => void;
  title: string;
  observation?: boolean;
  children?: React.ReactNode;
  onSubmit: Function;
  visible: boolean;
}
