import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ChatBox, { ChatFrame } from 'react-chat-plugin';
import { IChatHistory, IChatPlugin } from './interface';
import logoSut from './images/SUT_icon.png';
import user from './images/usuario.png';
import './chatbot.css';

export const ChatBot = () => {
  const [isTyping, setIsTyping] = useState(false);
  const [chatHistory, setChatHistory] = useState<IChatHistory[]>([]);
  const [attr, setAttr] = useState<IChatPlugin>({
    showChatbox: false,
    showIcon: true,
    messages: [
      {
        author: {
          id: 2,
          avatarUrl: logoSut,
        },
        timestamp: +new Date(),
        text: 'Hola, soy un asistente virtual. ¿En qué puedo ayudarte?',
        type: 'text',
      },
    ],
  });

  useEffect(() => {
    const container = document.querySelector('.react-chat-viewerBox');
    if (container) {
      const htmlElement = document.createElement('div');
      htmlElement.classList.add('react-chat-header');
      htmlElement.innerHTML = '<p>Atención al Usuario</p>';
      container.prepend(htmlElement);
    }
  }, [attr.showChatbox]);

  const handleChatHistory = (newObject) => {
    const CHAT_HISTORY_LENGTH = 4;
    const _chatHistory = chatHistory.slice();

    setChatHistory((prevState) => {
      const updatedHistory =
        chatHistory.length === CHAT_HISTORY_LENGTH ? [..._chatHistory.slice(1), newObject] : [...prevState, newObject];

      return updatedHistory;
    });
  };

  const handleClickIcon = () => {
    // toggle showChatbox and showIcon
    setAttr({
      ...attr,
      showChatbox: !attr.showChatbox,
      showIcon: !attr.showIcon,
    });
  };

  const setMessage = (message, id, avatarUrl) => {
    setAttr((prevState) => {
      return {
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            author: {
              id,
              avatarUrl,
            },
            text: message,
            type: 'text',
            timestamp: +new Date(),
          },
        ],
      };
    });
  };

  const setMessageLink = (message, link, id, avatarUrl) => {
    setAttr((prevState) => {
      return {
        ...prevState,
        messages: [
          ...prevState.messages,
          {
            author: {
              id,
              avatarUrl,
            },
            text: message,
            type: 'text',
            timestamp: +new Date(),
            buttons: link,
          },
        ],
      };
    });
  };

  const verifyAndCreateMessage = (message, id, avatarUrl) => {
    const linkDictionary = [
      {
        url: ' https://bit.ly/ordenanza-tasas-barinas.',
        title: 'Descargar Ordenanza Tasas Barinas',
      },
      { url: 'https://bit.ly/3T9sqbx' },
      { url: 'https://bit.ly/3V2ZwMK' },
      { url: 'https://bit.ly/cuentas-ecodebas', title: 'Descargar Cuentas ECODEBA' },
      { url: 'https://bit.ly/clasificador-ae-barinas', title: 'Descargar Clasificador De AE Barinas' },
      { url: 'https://bit.ly/cuentas-samat', title: 'Descargar Cuentas SAMAT' },
      { url: 'https://bit.ly/tarifas-aseo-barinas', title: 'Descargar Tarifas Aseo Barinas' },
    ];

    if (message.includes('https')) {
      let linkArray: any = [];
      linkDictionary.forEach((l) => {
        if (message.includes(l.url)) {
          linkArray.push({
            type: 'URL',
            payload: l?.url,
            title: l?.title ?? 'Descargar Recurso',
          });
          return;
        }
      });

      setMessageLink(message, linkArray, id, avatarUrl);
    } else {
      setMessage(message, id, avatarUrl);
    }
  };

  const handleOnSendMessage = async (message) => {
    try {
      setIsTyping(true);
      setMessage(message, 1, user);

      const response = await Axios.post(
        'https://l1ptmeprm7.execute-api.us-east-1.amazonaws.com/prod/barinas',
        {
          chat_history: chatHistory,
          prompt: message,
        },
        {
          headers: {
            'x-api-key': process.env.REACT_APP_CHATBOT_API_KEY,
          },
        }
      );

      const responseApi = response.data.result;
      handleChatHistory({ Human: message, AI: responseApi });

      verifyAndCreateMessage(responseApi, 2, logoSut);
    } catch (error) {
      const messageError = 'Lo siento, no puedo acceder a la información en este momento';
      setMessage(messageError, 2, logoSut);
      console.log(error);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <ChatFrame
      chatbox={
        <ChatBox
          onSendMessage={handleOnSendMessage}
          userId={1}
          messages={attr.messages}
          width={'300px'}
          showTypingIndicator={isTyping}
          activeAuthor={{ id: 2, avatarUrl: logoSut }}
          placeholder={'Envía un mensaje...'}
          timestampFormat='DD/MM/YYYY'
        ></ChatBox>
      }
      clickIcon={handleClickIcon}
      showChatbox={attr.showChatbox}
      showIcon={attr.showIcon}
      iconStyle={{ background: '#0071ce', fill: 'white' }}
    >
      <div className={'react-chat-greeting'}>👋 Hola soy un asistente virtual. ¿En qué puedo ayudarte?</div>
    </ChatFrame>
  );
};
