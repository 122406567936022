import React, { Fragment, useState } from 'react';
import { Modal, Row, Form, DatePicker, Col, Select, Input, Button, Divider, message } from 'antd';
import { useHistory, useParams } from 'react-router';
import handlingMessage from '../utils/handlingMessage';
import { BarChartOutlined } from '@ant-design/icons';
import { States, State } from 'sigt';
import { connect } from 'react-redux';
import {
  newReportPdf,
  reportCondo,
  newReportExcel,
  closureCash,
  clouserAllCash,
  newReportExcelIVA,
  reportDAE,
  reportTransf,
  reportTransfBank,
  reportCondoDisclosed,
  reportTimeFunctionary,
  reportPagosAprobados,
  reportPayments,
  reportEarnings,
  reportEcodebaGlobalLiqPag,
  reportTaxCredit,
} from '../services/report';
import moment from 'moment';
import { Utils } from '../utils/validators';
import { useWindowDimensions } from '../utils/hooks';

const Report: React.FC<ReportProps> = ({ auth, brch }) => {
  const [bankState, setBankState] = useState();
  const [form] = Form.useForm();
  const disabledDate = (current) => current > moment().endOf('day');
  const [loading, setLoading] = useState(false);
  const params: any = useParams();
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const handlerResquest = (type, token, values) => {
    switch (type) {
      case 3:
        return newReportExcel(values, token ? token : '');
      case 4:
        return newReportExcelIVA(values, token ? token : '');
      case 5:
        return closureCash(values.rango);
      case 6:
        return clouserAllCash(values);
      case 7:
        return reportDAE(auth.user?.contribuyente, values.rim, token);
      case 8:
        const [from, to] = values.rango;
        return reportTransf(
          { from: moment(from).utcOffset(-4).startOf('day'), to: moment(to).utcOffset(-4).endOf('day') },
          token
        );
      case 9:
        return reportCondo({ from: values.rango[0], to: values.rango[1] }, token);
      case 10:
        return reportTransfBank({ day: values.rango }, token, bankState);
      case 11:
        return reportCondoDisclosed({ from: values.rango[0], to: values.rango[1] }, token);
      case 12:
        return reportTimeFunctionary({ from: values.rango[0], to: values.rango[1] }, token);
      case 13:
        return reportPagosAprobados({ from: values.rango[0], to: values.rango[1] }, token);
      case 14:
        return reportPayments({ from: values.rango[0], to: values.rango[1] }, token);
      case 15:
        return reportEarnings({ day: values.rango }, token);
      case 16:
        return reportEcodebaGlobalLiqPag({ from: values.rango[0], to: values.rango[1] }, token);
      case 17:
        return reportTaxCredit({ from: values.rango[0], to: values.rango[1] }, token);
      default:
        return newReportPdf(values, token ? token : '');
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const values = await form.validateFields();
    values.alcaldia = +params.type === 2;
    handlingMessage({
      action: () => {
        return handlerResquest(+params.type, auth.token, values);
      },
      key: 'submit',
      loadingMessage: 'Realizando operacion...',
      cb: (data) => {
        if (data.status === 200) {
          console.log(data.data);
          const win = window.open(data.data.replaceAll('"', ''), '_blank');
          win?.focus();
        }
        setLoading(false);
      },
    });
  };

  const getReportDefault = (type: 'day' | 'month') => {
    form.setFieldsValue({ rango: [moment().utcOffset(-4).startOf(type), moment().utcOffset(-4).endOf(type)] });
    form.submit();
  };

  return (
    <React.Fragment>
      <Row style={{ height: '100%', width: '100%' }} justify='center' align='middle'>
        <BarChartOutlined style={{ color: 'lightgrey', fontSize: 250, textAlign: 'center' }} />
      </Row>
      <Modal
        maskClosable
        onCancel={() => history.goBack()}
        title='Reporte'
        okText='Generar'
        confirmLoading={loading}
        onOk={() => form.submit()}
        visible
        bodyStyle={{ margin: '0 35px' }}
      >
        {+params.type !== 5 &&
          +params.type !== 10 &&
          +params.type !== 11 &&
          +params.type !== 12 &&
          +params.type !== 13 &&
          +params.type !== 14 &&
          +params.type !== 15 && (
            <>
              <Row justify='space-between' gutter={[16, 8]}>
                <Col style={width < 768 ? { width: '100%' } : {}}>
                  {+params.type === 0 ? (
                    <Button
                      loading={loading}
                      style={{ width: '100%' }}
                      target='_blank'
                      href={'https://sigat-mcpo-barinas.s3.us-east-1.amazonaws.com/sat/reportes/RPRD.pdf'}
                      size={width < 768 ? 'middle' : 'small'}
                      type='primary'
                    >
                      Obtener reporte del día
                    </Button>
                  ) : (
                    <Button
                      loading={loading}
                      style={{ width: '100%' }}
                      onClick={() => getReportDefault('day')}
                      size={width < 768 ? 'middle' : 'small'}
                      type='primary'
                    >
                      Obtener reporte del día
                    </Button>
                  )}
                </Col>
                <Col style={width < 768 ? { width: '100%' } : {}}>
                  {+params.type === 0 ? (
                    <Button
                      loading={loading}
                      style={{ width: '100%' }}
                      target='_blank'
                      href={'https://sigat-mcpo-barinas.s3.us-east-1.amazonaws.com/sat/reportes/RPRM.pdf'}
                      size={width < 768 ? 'middle' : 'small'}
                    >
                      Obtener reporte del mes
                    </Button>
                  ) : (
                    <Button
                      loading={loading}
                      style={{ width: '100%' }}
                      onClick={() => getReportDefault('month')}
                      size={width < 768 ? 'middle' : 'small'}
                    >
                      Obtener reporte del mes
                    </Button>
                  )}
                </Col>
              </Row>
              <Divider />
            </>
          )}
        <Form onFinish={handleSubmit} form={form} layout='vertical'>
          <Row gutter={16}>
            <Col flex={1}>
              {+params.type === 7 ? (
                <Form.Item
                  label='R.I.M'
                  rules={[{ required: true, message: 'Por favor ingresar R.I.M!' }]}
                  normalize={Utils.normalize.toUpper}
                  name='rim'
                >
                  <Input placeholder='R.I.M' />
                </Form.Item>
              ) : (
                <Form.Item
                  label={+params.type === 5 || +params.type === 10 || +params.type === 15 ? null : 'Rango de fechas'}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresar fecha!',
                    },
                  ]}
                  name='rango'
                >
                  {+params.type === 5 || +params.type === 10 || +params.type === 15 ? (
                    <DatePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                  ) : (
                    <RangePicker format='DD/MM/YYYY' style={{ width: '100%' }} disabledDate={disabledDate} />
                  )}
                </Form.Item>
              )}
              {+params.type === 10 && (
                <Form.Item>
                  <Select placeholder={'Seleccione un banco'} onChange={(e) => setBankState(e)}>
                    <Select.Option value={2}> Bancamiga</Select.Option>
                    <Select.Option value={38}> Banco Nacional de Crédito</Select.Option>
                    <Select.Option value={39}> Banesco Banco Universal</Select.Option>
                    <Select.Option value={11}> Banco De Venezuela S.A.C.A. Banco Universal</Select.Option>
                    <Select.Option value={43}> Banco Bicentenario</Select.Option>
                    <Select.Option value={58}> Bfc Banco Fondo Común C.A. Banco Universal</Select.Option>
                    <Select.Option value={63}> Banco Del Tesoro</Select.Option>
                    <Select.Option value={65}> Banco Mercantil C.A.</Select.Option>
                    <Select.Option value={67}> Bancrecer S.A. Banco De Desarrollo</Select.Option>
                    <Select.Option value={68}> Bancaribe C.A. Banco Universal</Select.Option>
                    <Select.Option value={74}> Banco del Sur</Select.Option>
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          {+params.type === 3 ? (
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='ramo'>
                  <Select placeholder='Ramo'>
                    {brch.branches?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.descripcion}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          ) : null}
        </Form>
      </Modal>
    </React.Fragment>
  );
};
interface ReportProps {
  auth: States.Auth;
  brch: States.Branches;
}

const mapStateToProps = ({ auth, brch }: State) => ({ auth, brch });

export default connect(mapStateToProps)(Report);
