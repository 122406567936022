import React from 'react';
import { Layout, Row, Col } from 'antd';
import SamatLogo from '../assets/images/SAMAT.png';
import AlcaldiaLogo from '../assets/images/ALCALDIA.png';
import EcodebaLogo from '../assets/images/ECODEBA.png';
import '../assets/css/layouts/ExternalLayout.css';
import { useWindowDimensions } from '../utils/hooks';
import { useLocation } from 'react-router-dom';
import img from '../assets/images/suite-logo-white.png';
import sistemaCertificado from '../assets/images/SIGAT-GRIS.png';

const { Content, Header } = Layout;

const ExternalLayout: React.FC = ({ children }) => {
  const { width } = useWindowDimensions();
  const location = useLocation();

  return (
    <Layout id='main' style={{ height: '100vh' }}>
      {/* <Header id='header' style={{ padding: `0 60px`, zIndex: 2 }}>
        <Row
          gutter={60}
          align='stretch'
          justify={width < 480 ? 'center' : 'space-between'}
          style={{ flexWrap: 'nowrap', padding: 10 }}
        >
          {width < 480 ? null : (
            <React.Fragment>
              <Col>
                <div style={{ position: 'fixed', top: '3%', right: '2%', display: 'flex', gap: 20 }}>
                <img src={AlcaldiaLogo} height={150} width={250} alt='alcaldia' />
                </div>
              </Col>
              <Col>
                <div style={{ position: 'fixed', top: '3%', right: '2%', display: 'flex', gap: 20 }}>
                <img src={SamatLogo} height={120} width={180} alt='SamatLogo' />
                </div>
              </Col>
            </React.Fragment>
          )}
        </Row>
      </Header> */}
      <Content style={{ display: 'flex', zIndex: 2, alignItems: 'center', backgroundColor: 'transparent' }}>
        {location.pathname.includes('ingresar') ? (
          <Row id='login-content' align='middle' gutter={8}>
            <Col style={{ marginLeft: width > 400 ? 50 : 0 }}>{children}</Col>
          </Row>
        ) : (
          <Row id='main-content'>{children}</Row>
        )}

        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: 20,
            height: '100%',
            position: 'fixed',
            right: 0,
            paddingRight: 30,
            paddingBottom: 80,
            zIndex: -99999,
          }}
        > */}
        {/* <div
            onClick={() => {
              window.open('https://www.reportes.wakusoftware.com/sut.html', '_blank');
            }}
            className='burp-suite-logo'
          >
            <div
              style={{
                width: 68.2 * 3,
                height: 38.4 * 3,
                // position: 'fixed',
                // right: '4vw',
                // bottom: 0,
              }}
            ></div>
          </div> */}
        {/* {width < 480 ? null : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
              <img src={AlcaldiaLogo} height={120} width={150} alt='alcaldia' style={{ marginTop: 70 }} />
              <img src={SamatLogo} height={100} width={150} alt='samat' />
              <img src={EcodebaLogo} height={120} width={150} alt='ecodeba' />
              <img
                src={img}
                alt='asd'
                height={120}
                width={150}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open('https://www.reportes.wakusoftware.com/sut.html', '_blank');
                }}
              />
              <img src={sistemaCertificado} alt='asd' width={200} height={100} />
            </div>
          )} */}
        {/* </div> */}
      </Content>
    </Layout>
  );
};

export default ExternalLayout;
